import * as React from 'react';
import { useSetRecoilState } from 'recoil';
import { ICustomer } from '../../../interfaces/customer';
import * as CustomersState from '../../../store/recoil/customers';
import CustomerService from '../../Customers/CustomerService';

interface INavigatorSelectCustomersCustomerProps {
  customer: ICustomer;
  selected: boolean;
  alteredRowStyle: string;
  selectedServerId: number | null;
}

export default function Customer(props: INavigatorSelectCustomersCustomerProps): JSX.Element {
  const setSelectedTempCustomer = useSetRecoilState<number[]>(CustomersState.selectedTempCustomers);

  const handleSelectCustomer = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.currentTarget;
    const customerId = Number(value);

    if (checked) {
      setSelectedTempCustomer((oldState: number[]) =>
        CustomerService.addSelectedTempCustomer(oldState, customerId)
      );
    } else {
      setSelectedTempCustomer((oldState: number[]) =>
        CustomerService.removeSelectedTempCustomer(oldState, customerId)
      );
    }
  };

  const isDisabled = (serverId: number | null): boolean => {
    if (serverId === null) {
      return false;
    }
    return !(props.selectedServerId === props.customer.serverId);
  };

  return (
    <div
      className={`e-checkbox px-3 py-2 d-block text-black font-weight-normal ${props.alteredRowStyle}`}
    >
      <label htmlFor={`checkbox-customer-${props.customer.id}`}>
        <input
          id={`checkbox-customer-${props.customer.id}`}
          className={`customer-branch-${props.customer.branchId}`}
          type="checkbox"
          value={props.customer.id}
          checked={props.selected}
          onChange={handleSelectCustomer}
          disabled={isDisabled(props.selectedServerId)}
          data-cy={`customer-checkbox-${props.customer.id}`}
        />
        <span className="pl-7" data-cy={`customer-name-${props.customer.id}`}>
          {props.customer.name}
        </span>
      </label>
    </div>
  );
}
