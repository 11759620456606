import * as React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import * as CmdTableState from '../../CmdTable/CmdTableState';
import { ICmdTableColumn } from '../../CmdTable/interfaces';
import DateTimeCell from '../../CmdTable/TableCells/DateTimeCell';
import ThSimpleSelect from '../../CmdTable/TableHeadCell/ThSimpleSelect';
import ThMultiSelect from '../../CmdTable/TableHeadCell/ThMultiSelect';
import ThDateTime from '../../CmdTable/TableHeadCell/ThDateTime';
import TextCell from '../../CmdTable/TableCells/TextCell';
import {
  CAN_REFUELINGS_ACCOUNTING_KEY,
  CAN_REFUELINGS_AMOUNT_KEY,
  CAN_REFUELINGS_CUSTOMER_KEY,
  CAN_REFUELINGS_DUPLICATE_KEY,
  CAN_REFUELINGS_FUEL_TYPE_KEY,
  CAN_REFUELINGS_LICENSE_NUMBER_KEY,
  CAN_REFUELINGS_MODIFIED_AT_KEY,
  CAN_REFUELINGS_MODIFIED_BY_KEY,
  CAN_REFUELINGS_RIDE_KEY,
  CAN_REFUELINGS_STATUS_KEY,
  CAN_REFUELINGS_TABLE_KEY,
  CAN_REFUELINGS_VEHICLE_KEY,
  CanRefuelingsRecord,
  CanRefuelingsRecordRide,
  TableUserCell,
} from './types';
import ThText from '../../CmdTable/TableHeadCell/ThText';
import RideCell from '../../CmdTable/TableCells/RideCell';
import EmptyCell from '../../CmdTable/TableCells/EmptyCell';
import ThRange from '../../CmdTable/TableHeadCell/ThRange';
import BooleanCell from '../../CmdTable/TableCells/BooleanCell';
import { ACTIONS_KEY } from '../../CmdTable/constants';
import Actions from './RowActions/Actions';

interface UseCanRefuelings {
  columns: ColumnDef<CanRefuelingsRecord>[];
  columnOrder: string[];
  columnVisibility: { [key: string]: boolean };
}

export default function useCanRefuelings(): UseCanRefuelings {
  const { formatMessage: f } = useIntl();
  const columnsTable = useRecoilValue<ICmdTableColumn[]>(CmdTableState.columns);

  // const handleIndeterminateChange = (table) => {
  //   if (table.getIsSomeRowsSelected()) {
  //     // If currently indeterminate, unselect all.
  //     table.toggleAllRowsSelected(false);
  //   } else {
  //     // Otherwise, follow the default behavior.
  //     table.toggleAllRowsSelected(!table.getIsAllRowsSelected());
  //   }
  // };

  const columns = React.useMemo<ColumnDef<CanRefuelingsRecord>[]>(
    () => [
      {
        accessorKey: CAN_REFUELINGS_ACCOUNTING_KEY,
        header: (_table) => (
          <ThDateTime
            id={CAN_REFUELINGS_ACCOUNTING_KEY}
            tableName={CAN_REFUELINGS_TABLE_KEY}
            title={f({ id: `table_list.columns.canRefuelings.${CAN_REFUELINGS_ACCOUNTING_KEY}` })}
            isSortable={true}
            alwaysActive={true}
          />
        ),
        cell: (info) =>
          info.row.original?.accountingTs ? (
            <DateTimeCell
              datetime={info.getValue<string>()}
              minWidth={`${info.column.getSize()}px`}
            />
          ) : (
            <EmptyCell />
          ),
        minSize: 260,
      },
      {
        accessorKey: CAN_REFUELINGS_CUSTOMER_KEY,
        header: (_table) => (
          <ThMultiSelect
            tableName={CAN_REFUELINGS_TABLE_KEY}
            id={CAN_REFUELINGS_CUSTOMER_KEY}
            title={f({ id: `table_list.columns.customerId` })}
            isSortable={true}
          />
        ),
        cell: (info) =>
          info.row.original?.customer && info.row.original.customer.label ? (
            <TextCell text={info.getValue<{ id: number; label: string; name: string }>().label} />
          ) : (
            <EmptyCell />
          ),
        size: 120,
      },
      {
        accessorKey: CAN_REFUELINGS_STATUS_KEY,
        header: (table) => (
          <ThMultiSelect
            tableName={CAN_REFUELINGS_TABLE_KEY}
            id={CAN_REFUELINGS_STATUS_KEY}
            title={f({ id: `table_list.columns.${CAN_REFUELINGS_STATUS_KEY}` })}
            isSortable={true}
          />
        ),
        cell: (info) =>
          info.row.original?.status ? (
            <TextCell text={f({ id: `refuelings.status.${info.getValue<string>()}` })} />
          ) : (
            <EmptyCell />
          ),
        size: 120,
      },
      {
        accessorKey: CAN_REFUELINGS_LICENSE_NUMBER_KEY,
        header: (table) => (
          <ThMultiSelect
            tableName={CAN_REFUELINGS_TABLE_KEY}
            id={CAN_REFUELINGS_LICENSE_NUMBER_KEY}
            title={f({
              id: `table_list.columns.canRefuelings.${CAN_REFUELINGS_LICENSE_NUMBER_KEY}`,
            })}
            isSortable={true}
          />
        ),
        cell: (info) =>
          info.row.original?.licenceNumber ? (
            <TextCell text={info.getValue<string>()} />
          ) : (
            <EmptyCell />
          ),
        size: 120,
      },
      {
        accessorKey: CAN_REFUELINGS_RIDE_KEY,
        header: (_table) => (
          <ThText
            title={f({ id: `table_list.columns.canRefuelings.${CAN_REFUELINGS_RIDE_KEY}` })}
          />
        ),
        cell: (info) =>
          info.row.original?.ride ? (
            <RideCell
              data={info.getValue<CanRefuelingsRecordRide>()}
              customerId={info.row.original.customer.id}
              rideId={info.row.original.rideId}
            />
          ) : (
            <EmptyCell />
          ),
        size: 350,
      },
      {
        accessorKey: CAN_REFUELINGS_FUEL_TYPE_KEY,
        header: (_table) => (
          <ThSimpleSelect
            tableName={CAN_REFUELINGS_TABLE_KEY}
            id={CAN_REFUELINGS_FUEL_TYPE_KEY}
            title={f({ id: `table_list.columns.canRefuelings.${CAN_REFUELINGS_FUEL_TYPE_KEY}` })}
            isSortable={true}
          />
        ),
        cell: (info) =>
          info.row.original?.fuelType ? (
            <TextCell text={f({ id: `refuelings.fuelType.${info.getValue<string>()}` })} />
          ) : (
            <EmptyCell />
          ),
        size: 150,
      },
      {
        accessorKey: CAN_REFUELINGS_AMOUNT_KEY,
        header: (_table) => (
          <ThRange
            tableName={CAN_REFUELINGS_TABLE_KEY}
            id={CAN_REFUELINGS_AMOUNT_KEY}
            title={f({ id: `table_list.columns.canRefuelings.${CAN_REFUELINGS_AMOUNT_KEY}` })}
            tooltip={f({ id: `table_list.columns.canRefuelings.${CAN_REFUELINGS_AMOUNT_KEY}` })}
            isSortable={true}
            unit=""
          />
        ),
        cell: (info) =>
          info.row.original?.amount ? <TextCell text={info.getValue<string>()} /> : <EmptyCell />,
        size: 130,
      },
      {
        accessorKey: CAN_REFUELINGS_DUPLICATE_KEY,
        header: (_table) => (
          <ThSimpleSelect
            tableName={CAN_REFUELINGS_TABLE_KEY}
            id={CAN_REFUELINGS_DUPLICATE_KEY}
            title={f({ id: `table_list.columns.canRefuelings.${CAN_REFUELINGS_DUPLICATE_KEY}` })}
            isSortable={false}
          />
        ),
        cell: (info) =>
          info.row.original?.duplicate !== undefined ? (
            <BooleanCell
              value={info.getValue<boolean>()}
              trueValue={f({ id: 'canRefuelings.duplicate.yes' })}
              falseValue={f({ id: 'canRefuelings.duplicate.no' })}
            />
          ) : (
            <EmptyCell />
          ),
        minSize: 200,
      },
      {
        accessorKey: CAN_REFUELINGS_VEHICLE_KEY,
        header: (table) => (
          <ThMultiSelect
            tableName={CAN_REFUELINGS_TABLE_KEY}
            id={CAN_REFUELINGS_VEHICLE_KEY}
            title={f({ id: `table_list.columns.canRefuelings.vehicleName` })}
            isSortable={true}
          />
        ),
        cell: (info) =>
          info.row.original?.vehicle ? <TextCell text={info.getValue<string>()} /> : <EmptyCell />,
        minSize: 260,
      },
      {
        accessorKey: CAN_REFUELINGS_MODIFIED_BY_KEY,
        header: (_table) => (
          <ThMultiSelect
            tableName={CAN_REFUELINGS_TABLE_KEY}
            id={CAN_REFUELINGS_MODIFIED_BY_KEY}
            title={f({ id: `table_list.columns.${CAN_REFUELINGS_MODIFIED_BY_KEY}` })}
            isSortable={true}
          />
        ),
        cell: (info) =>
          info.row.original?.modifiedBy ? (
            <TextCell text={info.getValue<TableUserCell>().name || ''} />
          ) : (
            <EmptyCell />
          ),
        size: 150,
      },
      {
        accessorKey: CAN_REFUELINGS_MODIFIED_AT_KEY,
        header: (table) => (
          <ThDateTime
            id={CAN_REFUELINGS_MODIFIED_AT_KEY}
            tableName={CAN_REFUELINGS_TABLE_KEY}
            title={f({ id: `table_list.columns.${CAN_REFUELINGS_MODIFIED_AT_KEY}` })}
            isSortable={true}
          />
        ),
        cell: (info) =>
          info.row.original?.modifiedAt ? (
            <DateTimeCell datetime={info.getValue<string | null>()} format="D.M.YYYY H:mm" />
          ) : (
            <EmptyCell />
          ),
        size: 150,
      },
      {
        accessorKey: ACTIONS_KEY,
        header: () => (
          <div className=" th-sticky sticky-col detail">{f({ id: 'table.actionsBtn' })}</div>
        ),
        cell: (info) =>
          info.row.original ? <Actions<CanRefuelingsRecord> data={info.row.original} /> : null,
        size: 80, // Adjusted for consistency with header styling
        enablePinning: true,
      },
    ],
    [f]
  );

  const columnOrder = React.useMemo(() => {
    const order = columnsTable.map((item) => item.key);
    // add the detail and delete columns to the end
    // order.push(TAG_DETAIL_KEY, TAG_DELETED_AT_KEY);
    return order;
  }, [columnsTable]);

  const columnVisibility = React.useMemo(() => {
    return columnsTable.reduce((acc, column) => {
      acc[column.key] = !column.hidden;
      return acc;
    }, {});
  }, [columnsTable]);

  return {
    columns,
    columnOrder,
    columnVisibility,
  };
}
