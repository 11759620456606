import { atom } from 'recoil';
import { IRidePlayerSpeedLimitsSettings } from './interfaces';

export const speedlimitSettings = atom<IRidePlayerSpeedLimitsSettings>({
  key: 'speedlimitSettings',
  default: { minimumSpeed: 50, warningSpeed: 90, maximumSpeed: 130 },
});

export const actualPlayPosition = atom<number>({
  key: 'actualPlayPosition',
  default: 0,
});

export const activeRideNumber = atom<number>({
  key: 'activeRideNumber',
  default: 1,
  // effects: [
  //   ({ onSet }) => {
  //     onSet((newActiveRideNumber) => {
  //       console.debug('Active ride number changed: ', newActiveRideNumber);
  //     });
  //   },
  // ],
});

export const stop = atom<boolean>({
  key: 'stop',
  default: false,
});

export const pause = atom<boolean>({
  key: 'pause',
  default: false,
});

export const play = atom<boolean>({
  key: 'play',
  default: false,
});

export const next = atom<boolean>({
  key: 'next',
  default: false,
});

export const prev = atom<boolean>({
  key: 'prev',
  default: false,
});

export const activeLastRideId = atom<string | null>({
  key: 'activeLastRideId',
  default: null,
});

export const reloadLastRide = atom<boolean>({
  key: 'reloadLastRide',
  default: false,
});

export const showLastRideChangedWarning = atom<boolean>({
  key: 'showLastRideChangedWarning',
  default: false,
});

export const ignoreRideChange = atom<boolean>({
  key: 'ignoreRideChange',
  default: false,
});

export const enableArrows = atom<boolean>({
  key: 'enableArrows',
  default: true,
});

export const enableSpeedlimits = atom<boolean>({
  key: 'enableSpeedlimits',
  default: true,
});

export const enableWaitings = atom<boolean>({
  key: 'enableWaitings',
  default: true,
});

export const rideAutofocus = atom<boolean>({
  key: 'rideAutofocus',
  default: false,
});

export const enableFitBounds = atom<boolean>({
  key: 'enableFitBounds',
  default: true,
});

export const isRidePlayed = atom<boolean>({
  key: 'isRidePlayed',
  default: false,
});

export const playerSpeed = atom<number>({
  key: 'playerSpeed',
  default: 1,
});

export const showDataLayer = atom<boolean>({
  key: 'showDataLayer',
  default: false,
});

export const showSelectRidesModal = atom<boolean>({
  key: 'showSelectRidesModal',
  default: false,
});

export const selectedRides = atom<number[]>({
  key: 'selectedRides',
  default: [],
});

export const isRideActive = atom<number | false>({
  key: 'isRideActive',
  default: false,
});

export const autoplay = atom<boolean>({
  key: 'autoplay',
  default: false,
});

export const showAllRides = atom<boolean>({
  key: 'showAllRides',
  default: true,
});

export const activeRideIndex = atom<number>({
  key: 'activeRideIndex',
  default: 0,
});
