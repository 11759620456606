/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { useQueryClient } from '@tanstack/react-query';
import { ICustomWidgetProps } from './Widget';
import * as OnlinePanelState from '../OnlinePanelState';
import { SIZE_4X1, SIZE_4X2 } from './constants';
import { formatDateLastRideWidget } from './WidgetService';
import { IOnlinePanelDataForWidgets } from '../../OnlineMapNew/PanelsLayer/interfaces';

interface ILastRideData {
  start: {
    timestamp: string;
    address: string;
  };
  end: {
    timestamp: string;
    address: string;
  };
}

const mockLastRideData = {
  start: {
    timestamp: '2024-01-28T13:42:15Z',
    address: 'Púchovská 845, 861 06 Bratislava, Slovensko',
  },
  end: {
    timestamp: '2024-01-28T13:46:17Z',
    address: 'Tbiliská 4443, 871 06 Bratislava-Rača, Slovensko',
  },
};

export default function LastRideWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const vehicleId = useRecoilValue<number | false>(OnlinePanelState.isOnlinePanelOpen);
  const onDragStart = props.isInGrid ? undefined : props.onDragCallback;
  const unselectable = props.isInGrid ? undefined : 'on';
  const queryClient = useQueryClient();
  const lastRideData: IOnlinePanelDataForWidgets | undefined = queryClient.getQueryData([
    'widgetsDataForOnlinePanel',
    vehicleId,
  ]);

  const handleWidgetTooltip = () => {
    if (!props.isInGrid && props.isMockupMode) {
      return f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' });
    }
    return '';
  };

  let dataForWidget: ILastRideData | null = mockLastRideData;

  if (!Array.isArray(lastRideData?.last_ride)) {
    dataForWidget = lastRideData?.last_ride || mockLastRideData;
  } else if (Array.isArray(lastRideData?.last_ride)) {
    dataForWidget = null;
  } else if (props.isMockupMode) {
    dataForWidget = mockLastRideData;
  }

  return (
    <div
      data-cy="last-ride-widget"
      className={`b-widget  b-widget--${props.data.rowSize}x${props.data.columnSize}
      widget-grid-name-${props.data.gridName} ${
        props.data.hasIcon && props.data.columnSize > 1
          ? 'b-widget--alignLeft b-widget--carInfo'
          : ''
      } ${props.data.rowSize === 2 ? ' b-widget--2rows' : ''} widget-grid-name-${
        props.data.gridName
      } ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={onDragStart}
      unselectable={unselectable}
      title={handleWidgetTooltip()}
      style={{ position: 'relative', cursor: 'default' }}
    >
      {props.isInGrid && (
        <a
          data-cy="last-ride-widget-in-grid"
          style={{
            position: 'absolute',
            top: '1px',
            right: '1px',
            left: 'inherit',
            transform: 'inherit',
          }}
          href="#"
          title={f({ id: 'widgets.removeFromGrid.tooltip' })}
          className="cmd-icon cmd-icon-remove-sm close"
          data-grid-name={props.data.gridName}
          onClick={props.onRemoveCallback}
        />
      )}

      {props.data.size === SIZE_4X1 && (
        <>
          <i
            data-cy="last-ride-widget-icon-size-4-row-1"
            className="e-widget-icon cmd-icon cmd-icon-arrival-distance"
          />
          <div className="info">
            <div className="text-truncate">
              <strong>{f({ id: 'enum.onlineMap.widget.types.lastRide' })}</strong>{' '}
            </div>
          </div>
          {dataForWidget ? (
            <>
              <div
                className="info"
                title={`${formatDateLastRideWidget(dataForWidget.start?.timestamp, true)}: ${
                  dataForWidget?.start?.address
                }`}
              >
                <div className="text-truncate">
                  <strong>{`${formatDateLastRideWidget(
                    dataForWidget.start?.timestamp,
                    true
                  )}: `}</strong>
                  {`${dataForWidget.start?.address}`}
                </div>
              </div>
              <div
                className="info"
                title={`${formatDateLastRideWidget(dataForWidget.end.timestamp, true)}: ${
                  dataForWidget.end.address
                }`}
              >
                <div className="text-truncate">
                  <strong>{`${formatDateLastRideWidget(
                    dataForWidget.end.timestamp,
                    true
                  )}: `}</strong>
                  {`${dataForWidget.end.address}`}
                </div>
              </div>
            </>
          ) : (
            <div className="info" title={f({ id: 'enum.onlineMap.widget.types.lastRide.noData' })}>
              <div className="text-truncate">
                {f({ id: 'enum.onlineMap.widget.types.lastRide.noData' })}
              </div>
            </div>
          )}
        </>
      )}
      {props.data.size === SIZE_4X2 && (
        <>
          <i
            data-cy="last-ride-widget-icon-size-4-row-2"
            className="e-widget-icon cmd-icon cmd-icon-arrival-distance"
          />
          <div className="info">
            <div className="text-truncate">
              <strong>{f({ id: 'enum.onlineMap.widget.types.lastRide' })}</strong>{' '}
            </div>
          </div>
          {dataForWidget ? (
            <>
              <div
                className="info"
                title={`${formatDateLastRideWidget(dataForWidget.start.timestamp, true)}: ${
                  dataForWidget.start.address
                }`}
              >
                <div className="text-truncate">
                  <strong>{`${f({ id: 'alarms.modal.timeFrame.timeFrom' })}:`}</strong>{' '}
                  {formatDateLastRideWidget(dataForWidget.start.timestamp, true)}
                </div>
              </div>
              <div
                className="info"
                title={`${formatDateLastRideWidget(dataForWidget.start.timestamp, true)}: ${
                  dataForWidget.start.address
                }`}
              >
                <div className="text-truncate">{`${dataForWidget.start.address}`}</div>
              </div>
              <div
                className="info"
                title={`${formatDateLastRideWidget(dataForWidget.end.timestamp, true)}: ${
                  dataForWidget.end.address
                }`}
              >
                <div className="text-truncate">
                  <strong>{`${f({ id: 'alarms.modal.timeFrame.timeTo' })}:`}</strong>{' '}
                  {formatDateLastRideWidget(dataForWidget.end.timestamp, true)}
                </div>
              </div>
              <div
                className="info"
                title={`${formatDateLastRideWidget(dataForWidget.end.timestamp, true)}: ${
                  dataForWidget.end.address
                }`}
              >
                <div className="text-truncate">{`${dataForWidget.end.address}`}</div>
              </div>
            </>
          ) : (
            <div className="info" title={f({ id: 'enum.onlineMap.widget.types.lastRide.noData' })}>
              <div className="text-truncate">
                {f({ id: 'enum.onlineMap.widget.types.lastRide.noData' })}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
