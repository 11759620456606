/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { BeltDriverIcon, BeltPassengerIcon } from '@commander-services/cmd-icon';
import { IVehicleOnlinePanels } from '../OnlinePanelState';
import { ICustomWidgetProps } from './Widget';
import * as OnlinePanelState from '../OnlinePanelState';

const SEAT_BELT_DISABLED_COLOR = '#C8C8C8';
const SEAT_BELT_ON_COLOR = '#1C957A';
const SEAT_BELT_OFF_COLOR = '#B50536';

export default function SeatBeltFrontWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<{
    driver: boolean;
    frontPassenger: boolean;
  } | null>(null);

  const vehicleId = useRecoilValue<number | false>(OnlinePanelState.isOnlinePanelOpen);
  const onlinePanelData = useRecoilValue<IVehicleOnlinePanels>(OnlinePanelState.onlinePanel);

  React.useMemo(() => {
    if (!props.isMockupMode) {
      const onlinePanel = onlinePanelData[Number(vehicleId)];

      if (onlinePanel?.seatbelts && typeof vehicleId === 'number') {
        setWidgetData({
          driver: onlinePanel.seatbelts.driver,
          frontPassenger: onlinePanel.seatbelts.frontPassenger,
        });
      } else {
        setWidgetData(null);
      }
    }
  }, [onlinePanelData, props.isMockupMode, vehicleId]);

  const onDragStart = props.isInGrid ? undefined : props.onDragCallback;
  const unselectable = props.isInGrid ? undefined : 'on';

  const handleSeatBeltDriverIcon = () => {
    if (props.data.columnSize === 2 && props.data.rowSize === 1) {
      if (widgetData === null) {
        return <BeltDriverIcon color={SEAT_BELT_DISABLED_COLOR} />;
      }
      if (widgetData.driver === false && onlinePanelData[Number(vehicleId)].ignition) {
        return <BeltDriverIcon color={SEAT_BELT_OFF_COLOR} />;
      }
      if (widgetData.driver) {
        return <BeltDriverIcon color={SEAT_BELT_ON_COLOR} />;
      }
    }

    return <BeltDriverIcon />;
  };

  const handleSeatBeltPassengerIcon = () => {
    if (props.data.columnSize === 2 && props.data.rowSize === 1) {
      if (widgetData === null) {
        return <BeltPassengerIcon color={SEAT_BELT_DISABLED_COLOR} />;
      }
      if (widgetData.frontPassenger) {
        return <BeltPassengerIcon color={SEAT_BELT_ON_COLOR} />;
      }
    }

    return <BeltPassengerIcon />;
  };

  // Define specific tooltip messages for driver and front passenger
  const driverSeatTooltip = f({
    id: widgetData?.driver ? 'widget.setBeltDriver.tooltip.on' : 'widget.setBeltDriver.tooltip.off',
  });

  const frontPassengerSeatTooltip = f({
    id: widgetData?.frontPassenger
      ? 'widget.frontPassengerSeatBelt.tooltip.on'
      : 'widget.frontPassengerSeatBelt.tooltip.off',
  });

  return (
    <div
      data-cy="seat-belt-driver-widget"
      className={`p-0 b-widget b-widget--${props.data.rowSize}x${props.data.columnSize}
      widget-grid-name-${props.data.gridName} ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={onDragStart}
      unselectable={unselectable}
      style={{ position: 'relative' }}
    >
      {props.isInGrid && (
        <a
          data-cy="seat-belt-driver-widget-in-grid"
          style={{ position: 'absolute', top: '1px', right: '1px' }}
          href="#"
          title={f({ id: 'widgets.removeFromGrid.tooltip' })}
          className="cmd-icon cmd-icon-remove-sm close"
          data-grid-name={props.data.gridName}
          onClick={props.onRemoveCallback}
        />
      )}
      <div className="seatBeltContainer-widget">
        <div className="passengerIcon-widget" title={frontPassengerSeatTooltip}>
          {handleSeatBeltPassengerIcon()}
        </div>
        <div className="divider-line-widget" />
        <div className="driverIcon-widget" title={driverSeatTooltip}>
          {handleSeatBeltDriverIcon()}
        </div>
      </div>
    </div>
  );
}
