import * as AppState from '../store/recoil/AppState';
import { setRecoil } from '../components/RecoilOutside';
import * as NavigatorState from '../components/Navigator/NavigatorState';

const LoaderService = {
  showNavigatorLoader(value = true): void {
    setRecoil<boolean>(NavigatorState.showLoader, value);
  },

  showLoader(value = true): void {
    setRecoil<boolean>(AppState.setLoader, value);
  },
};

export default LoaderService;
