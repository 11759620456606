import { SetterOrUpdater } from 'recoil';
import { URL_REPORT_DOWNLOAD } from '../../router/constants';
import ApiService, { IS_OK } from '../../Services/ApiService';
import { IDownload, IDownloadLater } from './ExportState';

export const FILE_AVALABLE_TIME = 60000 * 60;
export const FILE_CHECK_TIME = 300000;

const ExportService = {
  async saveDownload(blob: Blob, fileName: string): Promise<void> {
    const csvURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', fileName);
    tempLink.click();
  },

  async downloadExport(
    exportData: IDownload,
    isTabActive: boolean,
    // exportRequests: string[],
    // downloads: IDownload[],
    // setExportRequests: SetterOrUpdater<string[]>,
    // setDownloadLater: SetterOrUpdater<IDownloadLater[]>,
    setDownloads: SetterOrUpdater<IDownload[]>,
    setIsActiveExport: SetterOrUpdater<false | string>
  ): Promise<void> {
    const jobId = exportData.jobId;
    console.info('jobid - downloading start ', jobId);
    const response = await ApiService.download(`/api/v2${URL_REPORT_DOWNLOAD}/${jobId}`, '');
    if (response && response.status === IS_OK) {
      const content = response.headers['content-disposition'];
      if (content) {
        const contentArr = content.split('"');
        let fileName = '';
        if (contentArr.length === 3) {
          fileName = contentArr[1];
        }
        let format = '';
        if (fileName) {
          const fileArr = fileName.split('.');
          if (fileArr.length > 1) {
            format = fileArr[fileArr.length - 1];
          }
          const mimeType =
            format === 'xlsx'
              ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              : 'application/octet-stream';

          const blob = new Blob([response.data], { type: mimeType });
          console.info('jobId and fileName - file saveAs ', jobId, fileName);

          if (isTabActive) {
            this.saveDownload(blob, fileName);
            setDownloads((oldState: IDownload[]) => {
              const job = oldState.find((item: IDownload) => item.jobId === jobId);
              if (job) {
                const updatedJob: IDownload = {
                  ...job,
                  isDownloaded: true,
                };
                const newState = [
                  ...oldState.filter((item: IDownload) => item.jobId !== jobId),
                  updatedJob,
                ];
                return newState;
              }
              return oldState;
            });
            // setIsActiveExport(false);
          } else {
            // setDownloadLater((oldState: IDownloadLater[]) => {
            //   const newReport: IDownloadLater = {
            //     code: exportData.code,
            //     jobId: exportData.jobId,
            //   };
            //   if (
            //     oldState.find(
            //       (item: IDownloadLater) =>
            //         item.code === exportData.code && item.jobId === exportData.jobId
            //     )
            //   ) {
            //     return oldState;
            //   }
            //   const newState: IDownloadLater[] = [...oldState, newReport];
            //   return newState;
            // });
          }

          // const requests = parsedRequest.filter(
          //   (i: string) => i !== jobId && i !== 'after-refresh'
          // );
          // setExportRequests(requests);
        }
      }
    }
  },
};

export default ExportService;
