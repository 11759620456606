import ApiService from '../../Services/ApiService';
import { IValidationApiResponse } from '../../Services/FormikValidatorService';
import { IChipInputItem } from '../Forms/Fields/ChipInput';
import {
  IAlarmApiResponse,
  IAlarmDefaultRecipientApiResponse,
  IAlarmTimeFrame,
  IAlarmValidationNotificationMessageApiResponse,
  IRecipient,
  TimeFrameFormDataType,
} from './interfaces';

const AlarmService = {
  async activateAlarm(id: string): Promise<any> {
    const response = await ApiService.put(`/v1/alarm/${id}/activate`, {}, true);
    return response ? response.data : null;
  },

  async deactivateAlarm(id: string): Promise<any> {
    const response = await ApiService.put(`/v1/alarm/${id}/deactivate`, {}, true);
    return response ? response.data : null;
  },

  async activateAlarmMultiple(ids: string[]): Promise<any> {
    const response = await ApiService.put(`/v1/alarm/activate`, { alarmId: ids }, true);
    return response ? response.data : null;
  },

  async deactivateAlarmMultiple(ids: string[]): Promise<any> {
    const response = await ApiService.put(`/v1/alarm/deactivate`, { alarmId: ids }, true);
    return response ? response.data : null;
  },

  /** Get alam validation */
  async getValidation(): Promise<IValidationApiResponse | null> {
    const response = await ApiService.get('/v1/alarm/validation');
    return response ? response.data : null;
  },

  // async setValidation(
  //   dispatchSetState: React.Dispatch<React.SetStateAction<IValidationApiResponse | null>>,
  //   dispatchSetLoaderState: React.Dispatch<React.SetStateAction<boolean>>
  // ): Promise<void> {
  //   const values: IValidationApiResponse | null = await this.getValidation();
  //   if (values) {
  //     dispatchSetState(values);
  //   }
  //   dispatchSetLoaderState(false);
  // },

  // async getNotificationMessageValidation(
  //   rideOwnership: string,
  //   ridePrivacy: string
  // ): Promise<IAlarmValidationNotificationMessageApiResponse | null> {
  //   const response = await ApiService.post('/v1/alarm/validation/notificationMessage', {
  //     rideOwnership,
  //     ridePrivacy,
  //   });
  //   return response ? response.data : null;
  // },

  async getNotificationMessageItems(
    rideOwnership: string,
    ridePrivacy: string
  ): Promise<IAlarmValidationNotificationMessageApiResponse | null> {
    const response = await ApiService.post('/v1/alarm/form/notification-message-tags', {
      rideOwnership,
      ridePrivacy,
    });
    return response ? response.data : null;
  },

  async getData(id: number): Promise<IAlarmApiResponse | null> {
    const response = await ApiService.get(`/v1/alarm/${id}`);
    if (response && response.data && response.status === 200) {
      const newAlarm = {
        ...response.data.alarm,
        timeFrames: response.data.alarm.timeFrames.map((group: IAlarmTimeFrame[]) => {
          return group.map((item: IAlarmTimeFrame, index: number) => {
            const newItem = {
              ...item,
              label: index > 0 ? ` + ${item.label} ` : item.label,
            };
            return newItem;
          });
        }),
      };
      const newResponse: IAlarmApiResponse = {
        alarm: newAlarm,
      };
      if (response && response.data.validation) {
        newResponse.validation = response.data.validation;
      }
      return newResponse;
    }
    return null;
  },

  prepareRecipients(recipients: IRecipient[]): IRecipient[] {
    const newRecipients = Object.values(recipients).map((i: any) => {
      if (!i.name) {
        if (i.fullname) {
          i.name = i.fullname;
        } else if (i.userId) {
          i.name = String(i.userId);
        } else {
          i.name = '';
        }
      }
      if (!i.telephone) {
        if (i.phone) {
          i.telephone = i.phone;
        } else {
          i.telephone = null;
        }
      }
      i.errorNotified = 0;
      i.id = i.id || null;
      i.userId = i.userId || null;
      i.email = i.email || null;
      i.viaMap = !!(i.viaMap && i.userId);
      i.viaRide = !!(i.viaRide && i.userId);
      i.viaEmail = !!(i.viaEmail && i.email);
      i.viaEmailPeriodicity = i.viaEmailPeriodicity || 'once_per_day';
      // i.viaSms = (i.notifications && i.notifications.includes('sms')) || false;
      // i.viaSmsPeriodicity = i.viaSmsPeriodicity || 'once_per_day';
      i.viaCall = !!(i.viaCall && (i.telephone || i.phone));
      i.viaCallPeriodicity = i.viaCallPeriodicity || 'once_per_day';
      i.viaPush = !!(i.viaPush && (i.telephone || i.phone));
      i.viaPushPeriodicity = i.viaPushPeriodicity || 'once_per_day';
      return i;
    });

    return newRecipients;
  },

  async getGroupSelectVehicles(
    rideOwnership: unknown,
    ridePrivacy: unknown,
    conditions: unknown,
    dispatchSetState: React.Dispatch<React.SetStateAction<any>>
  ): Promise<void> {
    const data = {
      rideOwnership,
      ridePrivacy,
      conditions,
    };
    const response = await ApiService.post('/v1/alarm/form/vehicleId', data);
    if (response && response.data && response.status === 200) {
      dispatchSetState(response.data);
    }
  },

  /** @deprecated: use getDefaultRecipient */
  async getRecipientValues(
    dispatchSetState: React.Dispatch<React.SetStateAction<any>>
  ): Promise<void> {
    const response = await ApiService.get('/v1/alarm/form/default-recipient');
    if (response && response.data && response.status === 200) {
      dispatchSetState([response.data]);
    }
  },

  /** Get default recipient */
  async getDefaultRecipient(): Promise<IAlarmDefaultRecipientApiResponse | null> {
    const response = await ApiService.get('/v1/alarm/form/default-recipient');
    if (response && response.data && response.status === 200) {
      return response.data;
    }
    return null;
  },

  createChipsForTimeFrames(values: TimeFrameFormDataType): IChipInputItem[] {
    const chips: IChipInputItem[] = values.map((groupItem: any) => {
      let chipId = 0;
      let chipLabel = '';
      groupItem.map((item: any, index: number) => {
        chipId = item.recordId;
        chipLabel += ` ${item.label} `;
      });
      const chipItem: IChipInputItem = {
        id: chipId,
        label: chipLabel,
      };
      return chipItem;
    });

    return chips;
  },

  createChipsForConditions(values): IChipInputItem[] {
    const chips = values.map((item: IChipInputItem) => {
      return item;
    });

    return chips;
  },
};

export default AlarmService;
