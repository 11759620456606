/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import CmdIcon from '@commander-services/cmd-icon';
import { IVehicleOnlinePanels } from '../OnlinePanelState';
import { ICustomWidgetProps } from './Widget';
import * as WidgetService from '../WidgetService';
import * as OnlinePanelState from '../OnlinePanelState';
import {
  BATTERY_LEVEL_95,
  BATTERY_LEVEL_20,
  BATTERY_LEVEL_40,
  BATTERY_LEVEL_60,
  BATTERY_LEVEL_80,
  LOW_BATTERY_LEVEL,
  MIDDLE_BATTERY_LEVEL,
} from './ElectroBatteryWidget';

const EV_RANGE_ICON = 'ev_range';
const EV_BATTERY_RANGE_ICON = 'ev_battery_range';
const LOW_BATTERY_COLOR = '#b50536';
const MIDDLE_BATTERY_COLOR = '';
const HIGH_BATTERY_COLOR = '#04b590';
const DEFAULT_COLOR = '#b3b3b3';

export default function ElectroRangeWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<{ range: number; percent: number } | null>({
    range: 60,
    percent: 99,
  });
  const vehicleId = useRecoilValue<number | false>(OnlinePanelState.isOnlinePanelOpen);
  const onlinePanelData = useRecoilValue<IVehicleOnlinePanels>(OnlinePanelState.onlinePanel);

  React.useMemo(() => {
    if (!props.isMockupMode) {
      const onlinePanel = onlinePanelData[Number(vehicleId)];

      if (
        onlinePanel &&
        onlinePanel.electric &&
        (onlinePanel.electric.batteryRange !== null || onlinePanel.electric.percent !== null)
      ) {
        setWidgetData({
          range: Number(Number(onlinePanel.electric.batteryRange).toFixed(2)),
          percent: Number(Number(onlinePanel.electric.percent).toFixed(2)),
        });
      } else {
        setWidgetData(null);
      }
    }
  }, [onlinePanelData, props.isMockupMode, vehicleId]);

  const getBatteryColor = () => {
    if (widgetData === null || widgetData.percent === null || widgetData.percent === undefined) {
      return 'text-gray-light';
    }
    if (widgetData.percent < LOW_BATTERY_LEVEL) {
      return 'text-primary';
    }
    if (widgetData.percent < MIDDLE_BATTERY_LEVEL) {
      return '';
    }
    return 'text-green';
  };

  const getBatteryLevelValue = () => {
    const batteryLevel = widgetData?.percent;
    if (batteryLevel === null || batteryLevel === undefined || batteryLevel >= BATTERY_LEVEL_95) {
      return '100';
    }
    if (batteryLevel >= BATTERY_LEVEL_80) {
      return '80';
    }
    if (batteryLevel >= BATTERY_LEVEL_60) {
      return '60';
    }
    if (batteryLevel >= BATTERY_LEVEL_40) {
      return '40';
    }
    if (batteryLevel >= BATTERY_LEVEL_20) {
      return '20';
    }
    return '0';
  };

  const getBatteryColorForCmdIcon = () => {
    if (widgetData === null || widgetData.percent === null || widgetData.percent === undefined) {
      return DEFAULT_COLOR;
    }
    if (widgetData.percent < LOW_BATTERY_LEVEL) {
      return LOW_BATTERY_COLOR;
    }
    if (widgetData.percent < MIDDLE_BATTERY_LEVEL) {
      return MIDDLE_BATTERY_COLOR;
    }
    return HIGH_BATTERY_COLOR;
  };

  return (
    <div
      data-cy="electro-range-widget"
      className={`b-widget b-widget--${props.data.rowSize}x${props.data.columnSize} ${
        props.data.hasIcon && props.data.columnSize > 1 ? 'b-widget--alignLeft' : ''
      } widget-grid-name-${props.data.gridName} ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={props.isInGrid ? undefined : props.onDragCallback}
      unselectable={props.isInGrid ? undefined : 'on'}
      title={WidgetService.getTooltip(
        f({ id: `widget.${props.data.icon}.tooltip` }),
        f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' }),
        props.isInGrid,
        props.isMockupMode
      )}
    >
      {props.isInGrid && (
        <a
          data-cy="battery-widget-in-grid"
          style={{
            position: 'absolute',
            top: '1px',
            right: '1px',
            left: 'inherit',
            transform: 'inherit',
          }}
          href="#"
          title={f({ id: 'widgets.removeFromGrid.tooltip' })}
          className="cmd-icon cmd-icon-remove-sm close"
          data-grid-name={props.data.gridName}
          onClick={props.onRemoveCallback}
        />
      )}
      {props.data.size === '1x1' && props.data.icon === EV_RANGE_ICON && (
        <div className="e-widget-value e-widget-value--sm">
          <div className="mb-2">{WidgetService.getValue(widgetData?.range, true, ' km')}</div>
          <div>{WidgetService.getValue(widgetData?.percent, true, ' %')}</div>
        </div>
      )}
      {props.data.size === '1x1' && props.data.icon === EV_BATTERY_RANGE_ICON && (
        <>
          <CmdIcon name="vehicle-range" color={getBatteryColorForCmdIcon()} size="24px" />
          <div className="e-widget-value mt-2">
            {WidgetService.getValue(widgetData?.range, true, ' km')}
          </div>
        </>
      )}
      {props.data.size === '2x1' && (
        <>
          <div className="row no-gutters my-1">
            <div className="col-auto">
              <CmdIcon name="vehicle-range" color={getBatteryColorForCmdIcon()} size="24px" />
            </div>
            <div className="col">
              <div className="e-widget-value">
                {WidgetService.getValue(widgetData?.range, true, ' km')}
              </div>
            </div>
          </div>
          <div className="row no-gutters my-1">
            <div className="col-auto">
              <i
                className={`e-widget-icon e-widget-icon--sm cmd-icon cmd-icon-battery-level-${getBatteryLevelValue()} ${getBatteryColor()}`}
              />
            </div>
            <div className="col">
              <div className="e-widget-value">
                {WidgetService.getValue(widgetData?.percent, true, ' %')}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
