import * as React from 'react';
import { useIntl } from 'react-intl';

export default function Error500() {
  const { formatMessage: f } = useIntl();
  return (
    <div className="error-page ">
      <img src="/images/errors/error500.svg" alt="" className="error-page-image" />
      <div className="error-text">
        <div className="error-text-field">
          <div className="self-stretch text-[32px] font-bold">{f({ id: 'error500.title' })}</div>
          <div className="self-stretch text-2xl font-normal">{f({ id: 'error500.subtitle' })}</div>
        </div>
        <a href="/" className="error-page-button">
          {f({ id: 'error404.btn.text' })}
        </a>
      </div>
    </div>
  );
}
