import { BBox } from 'geojson';
import { atom } from 'recoil';
import { IPosition } from '../../interfaces/map';
import UserService from '../../Services/UserService';
import setIsLastRidePlayerOpenToSocketServerEffect from '../../store/recoil/effects/setIsLastRidePlayerOpenToSocketServerEffect';
import { IRide, IRidePosition } from '../RidePlayer/interfaces';
import { IUserMapSettings } from './interfaces';
import { DEFAULT_ZOOM, MIN_POINTS_FOR_DISABLED_CLUSTER } from './GoogleMapLayer/constants';

export const zoom = atom<number>({
  key: 'onlineMapState_zoom',
  default: DEFAULT_ZOOM,
});

export const autofocus = atom<boolean>({
  key: 'onlineMapState_autofocus',
  default: true,
});
export const vehicleFocus = atom<boolean>({
  key: 'onlineMapState_vehiclefocus',
  default: false,
});
export const isClusterEnabled = atom<boolean>({
  key: 'onlineMapState_isClusterEnabled',
  default: UserService.getMapSettings().value.clusters,
});
export const isRidePlayerMenuOpen = atom<boolean>({
  key: 'onlineMapState_isRidePlayerMenuOpen',
  default: false,
});
export const isRidePlayerOpen = atom<number | boolean>({
  key: 'onlineMapState_isRidePlayerOpen',
  default: false,
  effects: [setIsLastRidePlayerOpenToSocketServerEffect()],
});
export const isTrafficLayerOpen = atom<boolean>({
  key: 'onlineMapState_isTrafficLayerOpen',
  default: false,
});

export const isFullscreen = atom<boolean>({
  key: 'onlineMapState_isFullscreen',
  default: false,
});

export const isSearchBarOpen = atom<boolean>({
  key: 'onlineMapState_isSearchBarOpen',
  default: false,
});

export const isAllSimpleWindowsOpen = atom<boolean>({
  key: 'onlineMapState_isAllSimpleWindowsOpen',
  default: false,
});

export const filteredVehicles = atom<number[]>({
  key: 'onlineMapState_onlineMapFilteredVehicles',
  default: [],
});

export const bounds = atom<BBox | undefined>({
  key: 'onlineMapState_bounds',
  default: undefined,
});

export const center = atom<IPosition>({
  key: 'onlineMapState_center',
  default: {
    lat: 48.75946,
    lng: 17.29349,
  },
});
// export const selectedVehiclesWithData = atom<IVehicles>({
//   key: 'onlineMapState_onlineMapSelectedVehiclesWithData',
//   default: {},
// });

export const filterString = atom<string>({
  key: 'onlineMapState_filterStringOnMap',
  default: '',
});

export const mapSettings = atom<IUserMapSettings>({
  key: 'onlineMapState_mapSettings',
  default: UserService.getMapSettings(),
});

export const lastRideForSelectedVehicle = atom<IRide | null>({
  key: 'onlineMapState_lastRideForSelectedVehicle',
  default: null,
});

export const currentRidePositionForSelectedVehicle = atom<IRidePosition | null>({
  key: 'onlineMapState_currentRidePositionForSelectedVehicle',
  default: null,
});

export const isStreetviewEnabled = atom<boolean>({
  key: 'onlineMapState_isStreetviewEnabled',
  default: false,
});

export const minPoints = atom<number>({
  key: 'onlineMapMinPoints',
  default: MIN_POINTS_FOR_DISABLED_CLUSTER,
});
