import * as React from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import LoaderService from '../../../Services/LoaderService';
import {
  ICmdTableActionFilter,
  ICmdTableFilter,
  IFilterChipsValues,
  ITLCFilterTable,
} from '../interfaces';
import * as CmdTableState from '../CmdTableState';
import { ASCENDING_KEY, DESCENDING_KEY } from '../constants';
import { getFilterCmdTableData } from '../CmdTableService';
import * as CustomersState from '../../../store/recoil/customers';
import { VEHICLE_SETTINGS_CARD_READER } from '../../VehiclesSettings/constants';

interface IUseFilter {
  updateFilterItemsState: (data: ICmdTableActionFilter[]) => void;
  getFilterData: (
    tableName: string,
    filterId: string,
    dataForFilter?: ITLCFilterTable,
    enableMessage?: boolean
  ) => Promise<void>;
  handleOnSort: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const useFilter = (tableIdentifier: string): IUseFilter => {
  const [sortFilter, setSortFilter] = useRecoilState<{ [key: string]: string }>(
    CmdTableState.sortFilter(tableIdentifier)
  );
  const selectedCustomers = useRecoilValue<number[]>(CustomersState.selectedCustomers);
  const [tableFiltersKey, setTableFiltersKey] = useRecoilState<string[]>(
    CmdTableState.tableFilters(tableIdentifier)
  );
  const setFilterForRequest = useSetRecoilState<ICmdTableFilter>(
    CmdTableState.filterForRequest(tableIdentifier)
  );
  const prevSelectedCustomersRef = React.useRef<number[]>(selectedCustomers);

  const updateFilterItemsState = useRecoilCallback(
    ({ set, snapshot }) =>
      async (data: ICmdTableActionFilter[]) => {
        const tableFilters = await snapshot.getPromise(CmdTableState.tableFilters(tableIdentifier));
        const ids: string[] = [];
        data.forEach((filterItem: ICmdTableActionFilter) => {
          ids.push(String(filterItem.id));
          set(
            CmdTableState.tableFilter({ [tableIdentifier]: String(filterItem.id) }),
            () => filterItem
          );
        });
        if (data[0] && !tableFilters.includes(String(data[0].id))) {
          const newTableFilterIds: string[] = [...tableFilters, ...ids];
          set(CmdTableState.tableFilters(tableIdentifier), newTableFilterIds);
        }
      }
  );

  const resetFilterItemsState = useRecoilCallback(({ reset }) => (filterIds: string[]) => {
    filterIds.forEach((filterId) => {
      reset(CmdTableState.tableFilter({ [tableIdentifier]: filterId }));
    });
  });
  const isArrayOfFilterChipsValues = (
    values: IFilterChipsValues[] | null
  ): values is IFilterChipsValues[] => {
    return (
      Array.isArray(values) &&
      values.every((item) => typeof item === 'object' && 'value' in item && 'item' in item)
    );
  };

  const processFilterValues = (
    filterId: string,
    values: IFilterChipsValues[] | null
  ): IFilterChipsValues[] | null => {
    if (!isArrayOfFilterChipsValues(values)) {
      return values;
    }

    switch (filterId) {
      case VEHICLE_SETTINGS_CARD_READER:
        return values.map((item) => ({
          ...item,
          item: typeof item.item === 'string' ? item.item.toUpperCase() : item.item, // Convert item to uppercase if it's a string
        }));

      default:
        return values; // If no specific rules, return the original values
    }
  };

  const getFilterData = async (
    tableName: string,
    filterId: string,
    dataForFilter?: ITLCFilterTable,
    enableMessage?: boolean
  ): Promise<void> => {
    LoaderService.showLoader(true);
    const filterData = await getFilterCmdTableData(tableName, filterId, enableMessage);

    if (filterData) {
      const newDataForFilter: ITLCFilterTable = {
        type: filterData.type,
        value: filterData.value,
        values: filterData.values
          ? processFilterValues(filterId, filterData.values)
          : filterData.values,
        required: filterData.required,
      };

      updateFilterItemsState([
        {
          id: filterId,
          isOpen: true,
          isActionChipsOpen: false,
          data: newDataForFilter,
          values: null,
        },
      ]);
    }

    if (!filterData && dataForFilter) {
      updateFilterItemsState([
        { id: filterId, isOpen: true, isActionChipsOpen: false, data: dataForFilter, values: null },
      ]);
    }

    LoaderService.showLoader(false);
  };

  const handleOnSort = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    const columnId = event.currentTarget.dataset.columnId;

    if (columnId) {
      if (sortFilter[columnId] === ASCENDING_KEY) {
        setSortFilter({ [columnId]: DESCENDING_KEY });
        // setOffset(0);
      } else {
        setSortFilter({ [columnId]: ASCENDING_KEY });
        // setOffset(0);
      }
    }
  };

  React.useEffect(() => {
    if (
      selectedCustomers.length !== prevSelectedCustomersRef.current.length ||
      !selectedCustomers.every((val, index) => val === prevSelectedCustomersRef.current[index])
    ) {
      resetFilterItemsState(tableFiltersKey);
      setTableFiltersKey([]);
      setFilterForRequest({});
      prevSelectedCustomersRef.current = selectedCustomers;
    }
  }, [selectedCustomers]);

  return {
    updateFilterItemsState,
    getFilterData,
    handleOnSort,
  };
};

export default useFilter;
