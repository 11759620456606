import { AxiosResponse } from 'axios';
import { IPermissions } from '../../interfaces/permission';
import PermissionsService from '../../Services/PermissionsService';
import { GdprProfilesResponse, PermissionsProfilesResponse, UserResponse } from './types';
import { ICustomer } from '../../interfaces/customer';
import UserService from '../../Services/UserService';
import { getRequest } from '../../Services/HttpService';

export const getUserData = async (): Promise<
  UserResponse | false | AxiosResponse<unknown, any>
> => {
  return getRequest<UserResponse>('v2/auth/user', {
    showErrorToaster: [500],
    showToaster: false,
  });
};

export const getGdprProfiles = async (): Promise<
  GdprProfilesResponse | false | AxiosResponse<unknown, any>
> => {
  return getRequest<GdprProfilesResponse>('v2/auth/gdpr-profiles', {
    showErrorToaster: false,
    showToaster: false,
  });
};

export const getCustomers = async (): Promise<
  PermissionsProfilesResponse | false | AxiosResponse<unknown, any>
> => {
  return getRequest<PermissionsProfilesResponse>('v2/auth/customers', {
    showErrorToaster: false,
    showToaster: false,
  });
};

export const setPermissions = (data: PermissionsProfilesResponse): void => {
  const permissions: IPermissions = {};
  const customerIds = Object.keys(data);
  customerIds.map((customerId: string) => {
    // Old ACL structure vs. New (soon again also old) ACL structure :D
    permissions[customerId] = data[customerId].permissions[customerId]
      ? data[customerId].permissions[customerId]
      : data[customerId].permissions;
  });
  PermissionsService.save(permissions);
};

export const createCustomersArray = (data: PermissionsProfilesResponse): ICustomer[] => {
  return Object.keys(data).map((key) => {
    const customerItem: ICustomer = {
      id: data[key].id,
      name: data[key].name,
      shortName: data[key].shortName,
      branchId: data[key].salesChannelId,
      label: data[key].label,
    };
    return customerItem;
  });
};

export const createCustomerIdsArray = (customers: ICustomer[]) => {
  return customers.map((customer: ICustomer) => customer.id);
};

export const removeUnusedCustomersFromDefaultCustomers = (customerIds: number[]): number[] => {
  const defaultCustomers: number[] = UserService.getDefaultCustomer();
  const newDefaultCustomers: number[] = defaultCustomers.filter((customerId: number) =>
    customerIds.includes(customerId)
  );
  UserService.setDefaultCustomer(newDefaultCustomers);
  return newDefaultCustomers;
};
