/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { orderBy } from 'lodash';
import { CmdModal, CmdModalFooterButton } from '@commander-services/gui-components';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { IRide, IRideDetail } from './interfaces';
import * as RidePlayerState from './RidePlayerState';
import { showMessage } from '../Toastr/ToastService';
import { IS_GPS_RIDE, IS_MANUAL_RIDE } from '../TripsOverviewNew/constants';

interface ISelectRidesModalProps {
  rides: IRide[];
  submitCallback: () => void;
}

interface ISortByColumn {
  column: string;
  type: 'asc' | 'desc';
}

dayjs.extend(duration);

const COLUMN_DRIVER = 'driverName';
const COLUMN_ALARMS = 'totalAlarms';
const COLUMN_PAUSE = 'totalPauses';
const COLUMN_EVENTS = 'totalEvents';
const COLUMN_WAYPOINTS = 'totalWaypoints';
const COLUMN_DATE = 'startDate';
const COLUMN_MODE = 'private';
const COLUMN_DISTANCE = 'totalDistance';
const COLUMN_DURATION = 'totalDuration';
const COLUMN_ECOSCORE = 'ecoScore';
const ASC = 'asc';
const DESC = 'desc';

/**
 * Calculate duration from seconds to format like: 12h 35m 14s
 * @param {string} startTime ISO8601
 * @param {string} endTime ISO8601
 * @returns {string}
 */
export const calculateDuration = (startTime: string, endTime: string): string => {
  const durationInSeconds = dayjs(endTime).diff(dayjs(startTime), 's');
  if (durationInSeconds <= 59) {
    return dayjs.duration(durationInSeconds, 'seconds').format('s[s]');
  }
  if (durationInSeconds > 60 && durationInSeconds < 3600) {
    return dayjs.duration(durationInSeconds, 'seconds').format('m[m] s[s]');
  }
  return dayjs.duration(durationInSeconds, 'seconds').format('H[h] m[m] s[s]');
};

/**
 * Format duration from seconds
 * @param {number} durationInSeconds
 * @returns {string}
 */
const formatDuration = (durationInSeconds: number | undefined): string => {
  if (!durationInSeconds) {
    return '';
  }
  if (durationInSeconds <= 59) {
    return dayjs.duration(durationInSeconds, 'seconds').format('s[s]');
  }
  if (durationInSeconds >= 60 && durationInSeconds < 3600) {
    return dayjs.duration(durationInSeconds, 'seconds').format('m[m] s[s]');
  }
  return dayjs.duration(durationInSeconds, 'seconds').format('H[h] m[m] s[s]');
};

export default function SelectRidesModal(props: ISelectRidesModalProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const setShowSelectRidesModal = useSetRecoilState<boolean>(RidePlayerState.showSelectRidesModal);
  const [selectedRides, setSelectedRides] = useRecoilState<number[]>(RidePlayerState.selectedRides);

  const filteredSelectedRides = props.rides
    .filter(
      (ride: IRide) =>
        ride.rideData.source === IS_GPS_RIDE && selectedRides.includes(ride.rideData.key)
    )
    .map((ride: IRide) => ride.rideData.key);

  const [tempSelectedRides, setTempSelectedRides] = React.useState<number[]>(filteredSelectedRides);
  const setActiveRideIndex = useSetRecoilState<number>(RidePlayerState.activeRideIndex);
  const setActualPlayPosition = useSetRecoilState<number>(RidePlayerState.actualPlayPosition);
  const [sortByColumn, setSortByColumn] = React.useState<ISortByColumn>({
    column: 'date',
    type: 'asc',
  });
  const [sortedRides, setSortedRides] = React.useState<IRideDetail[]>(
    props.rides.map((ride: IRide, index: number) => {
      const lastPositionIndex = ride.positions.length - 1;
      const startDate = ride.rideData.start.timestamp;
      const endDate = ride.rideData.end.timestamp;
      const startAddress = ride.rideData.start.address;
      const endAddress = ride.rideData.end.address;

      const totalDistance =
        ride.rideData.source === IS_GPS_RIDE && ride.positions[lastPositionIndex]
          ? Number(ride.positions[lastPositionIndex].distanceCumulative.toFixed(2))
          : ride.rideData.distance.toFixed(2);
      const totalDuration =
        ride.positions[lastPositionIndex] && ride.positions[lastPositionIndex].timestamp
          ? dayjs(endDate).diff(dayjs(startDate), 's')
          : 0;
      const totalAlarms =
        ride.rideData.info && ride.rideData.info.alarm ? Number(ride.rideData.info.alarm) : 0;
      const totalWaypoints =
        ride.rideData.info && ride.rideData.info.waypoint ? Number(ride.rideData.info.waypoint) : 0;
      const totalEvents =
        ride.rideData.info && ride.rideData.info.event ? Number(ride.rideData.info.event) : 0;
      const isPrivate = ride.rideData.private;

      const newRideData: IRideDetail = {
        ...ride.rideData,
        startDate,
        endDate,
        startAddress,
        endAddress,
        totalDistance: Number(totalDistance),
        totalDuration,
        totalAlarms,
        totalEvents,
        totalWaypoints,
        private: isPrivate,
      };
      return newRideData;
    })
  );

  const numberOfCheckedRides = React.useCallback(() => {
    const checked = tempSelectedRides.length;
    return checked;
  }, [tempSelectedRides]);

  React.useEffect(() => {
    const element: HTMLElement | null = document.getElementById('select-all-checkbox');
    if (numberOfCheckedRides() > 0 && numberOfCheckedRides() < props.rides.length && element) {
      (element as HTMLInputElement).indeterminate = true;
    }
    if (
      numberOfCheckedRides() === props.rides.length ||
      (numberOfCheckedRides() === 0 && element)
    ) {
      (element as HTMLInputElement).indeterminate = false;
    }
  }, [numberOfCheckedRides, props.rides.length, tempSelectedRides]);

  React.useEffect(() => {
    const newSortedRides = orderBy(sortedRides, sortByColumn.column, sortByColumn.type);
    setSortedRides(newSortedRides);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortByColumn]);

  React.useEffect(() => {
    const submitButton = document.getElementById('submit-select-rides-modal');
    if (submitButton && numberOfCheckedRides() === 0) {
      const isDisabled = submitButton.getAttribute('disabled');
      if (!isDisabled) {
        submitButton.setAttribute('disabled', 'disabled');
      }
      if (isDisabled === 'disabled') {
        submitButton.removeAttribute('disabled');
      }
      showMessage(
        f({ id: 'toast.ridePlayer.selectRidesModal.noSelectedRides.title' }),
        f({ id: 'toast.ridePlayer.selectRidesModal.noSelectedRides.message' }),
        'warning',
        4000
      );
    }
    if (submitButton && numberOfCheckedRides() > 0) {
      const isDisabled = submitButton.getAttribute('disabled');
      if (isDisabled === 'disabled') {
        submitButton.removeAttribute('disabled');
      }
    }
  }, [f, numberOfCheckedRides, tempSelectedRides]);

  const handleCloseModal = () => {
    setShowSelectRidesModal(false);
  };

  const handleSave = () => {
    setSelectedRides(tempSelectedRides);
    setActiveRideIndex(0);
    setActualPlayPosition(0);
    props.submitCallback();
    setShowSelectRidesModal(false);
  };

  const buttonsOneRide: CmdModalFooterButton[] = [
    {
      id: 'close-select-rides-modal',
      type: 'button',
      title: f({ id: 'form.close' }),
      className: 'e-button e-button--gray',
      closeCallback: handleCloseModal,
    },
  ];

  const buttonsMoreRides: CmdModalFooterButton[] = [
    {
      id: 'close-select-rides-modal',
      type: 'button',
      title: f({ id: 'waypoint.confirm.dialog.cancel' }), // Vulgarny quickhack. V aktualnom case tejto zmeny, mi nesli zmenit preklady, lebo Anton tam ma limit na upload (440 nieco kb) a uz samotne preklady ho prekracuju
      className: 'e-button e-button--gray',
      closeCallback: handleCloseModal,
    },
    {
      id: 'submit-select-rides-modal',
      type: 'submit',
      title: f({ id: 'ridePlayer.selectRidesModal.button.submit' }),
      className: 'e-button',
      submitCallback: handleSave,
    },
  ];

  const modalTitle = () => {
    const numberOfSelectedRides = tempSelectedRides.length;
    return f(
      { id: 'ridePlayer.selectRidesModal.title' },
      { numberOfSelectedRides, numberOfRides: props.rides.length }
    );
  };

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rideNumber = event.currentTarget.value;
    if (rideNumber) {
      setTempSelectedRides((oldState: number[]) => {
        if (oldState.includes(Number(rideNumber))) {
          return oldState.filter((ride: number) => ride !== Number(rideNumber));
        }
        return [...oldState, Number(rideNumber)].sort((a, b) => a - b);
      });
    }
  };

  const handleSort = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    const sortColumn = event.currentTarget.getAttribute('data-sort-column');
    const sortType = event.currentTarget.getAttribute('data-sort-type');
    if (sortColumn && sortType) {
      setSortByColumn((oldState: ISortByColumn) => {
        const newState: ISortByColumn = {
          ...oldState,
        };
        if (oldState.column !== sortColumn) {
          newState.column = sortColumn;
          newState.type = ASC;
        }
        if (oldState.column === sortColumn && sortType === ASC) {
          newState.type = DESC;
        }
        if (oldState.column === sortColumn && sortType === DESC) {
          newState.type = ASC;
        }
        return newState;
      });
    }
  };

  const handleSelectAll = (_event: React.ChangeEvent<HTMLInputElement>) => {
    const rideNumbersElements = document.querySelectorAll('input.ride-number');
    const selected: number[] = [];
    if (numberOfCheckedRides() === 0) {
      rideNumbersElements.forEach((element: Element) => {
        const value = (element as HTMLInputElement).value;
        const rideSource = (element as HTMLInputElement).dataset.source;
        if (value && rideSource === IS_GPS_RIDE) {
          selected.push(Number(value));
        }
      });
      setTempSelectedRides(selected);
    } else {
      rideNumbersElements.forEach((element: Element) => {
        const isChecked = (element as HTMLInputElement).checked;
        if (isChecked) {
          (element as HTMLInputElement).checked = false;
        }
        setTempSelectedRides([]);
      });
    }
  };

  const getSortIconClass = (column: string): string => {
    if (sortByColumn && sortByColumn.column === column) {
      if (sortByColumn.type === ASC) {
        return 'cmd-icon-sorting-a-z text-active';
      }
      return 'cmd-icon-sorting-z-a text-active';
    }
    return 'cmd-icon-sorting-a-z text-gray';
  };

  const getSortType = (column: string): string => {
    if (column === sortByColumn.column) {
      return sortByColumn.type === ASC ? ASC : DESC;
    }
    return ASC;
  };

  const getEcoDriveIconClass = (ecoScore: number) => {
    if (ecoScore < 5) {
      return 'cmd-icon-eco-drive-bad';
    }
    if (ecoScore < 8) {
      return 'cmd-icon-eco-drive-not-good';
    }
    return 'cmd-icon-eco-drive-good';
  };

  const getEcoDriveIconColor = (ecoScore: number): React.CSSProperties => {
    if (ecoScore < 5) {
      return { color: '#b50536' };
    }
    if (ecoScore < 8) {
      return { color: '#df731d' };
    }
    return { color: '#04b590' };
  };

  return (
    <CmdModal
      id="select-rides-modal"
      title={modalTitle()}
      footerButtons={props.rides.length === 1 ? buttonsOneRide : buttonsMoreRides}
      size="xl"
    >
      <div className="b-select-ride-table">
        <div className="table-responsive height-auto">
          <table className="table table-striped table-bordered table-listings m-0 dynamic-table">
            <thead>
              <tr>
                <th className="th-sticky-top w-sm">
                  <div className="e-checkbox checkbox-sm">
                    <label>
                      <input
                        id="select-all-checkbox"
                        type="checkbox"
                        checked={numberOfCheckedRides() === props.rides.length}
                        disabled={props.rides.length === 1}
                        onChange={handleSelectAll}
                      />
                      <span className="p-0" />
                    </label>
                  </div>
                </th>
                <th
                  className="th-sticky-top"
                  title={f({ id: 'ridePlayer.selectRidesModal.table.ride.tooltip' })}
                >
                  {f({ id: 'ridePlayer.selectRidesModal.table.ride' })}{' '}
                </th>
                <th
                  className="th-sticky-top"
                  title={f({ id: 'ridePlayer.selectRidesModal.table.dateAndTime.tooltip' })}
                >
                  {f({ id: 'ridePlayer.selectRidesModal.table.dateAndTime' })}
                  <a
                    id="sort-date"
                    href="#"
                    title={f({
                      id: `table.filter.${getSortType(COLUMN_DATE).toLocaleLowerCase()}`,
                    })}
                    onClick={handleSort}
                    data-sort-column={COLUMN_DATE}
                    data-sort-type={getSortType(COLUMN_DATE)}
                    className={`filter cmd-icon ${getSortIconClass(
                      COLUMN_DATE
                    )} text-decoration-none position-relative ml-2`}
                  />
                </th>
                <th
                  className="th-sticky-top"
                  style={{ textTransform: 'capitalize' }}
                  title={f({ id: 'ridePlayer.selectRidesModal.table.mode.tooltip' })}
                >
                  {f({ id: 'ridePlayer.selectRidesModal.table.mode' })}
                  <a
                    id="sort-mode"
                    href="#"
                    title={f({
                      id: `table.filter.${getSortType(COLUMN_MODE).toLocaleLowerCase()}`,
                    })}
                    onClick={handleSort}
                    data-sort-column={COLUMN_MODE}
                    data-sort-type={getSortType(COLUMN_MODE)}
                    className={`filter cmd-icon ${getSortIconClass(
                      COLUMN_MODE
                    )} text-decoration-none position-relative ml-2`}
                  />
                </th>
                <th
                  className="th-sticky-top"
                  title={f({ id: 'ridePlayer.selectRidesModal.table.address.tooltip' })}
                >
                  {f({ id: 'ridePlayer.selectRidesModal.table.address' })}
                </th>
                <th
                  className="th-sticky-top text-right"
                  title={f({ id: 'ridePlayer.selectRidesModal.table.waypointsLong' })}
                >
                  {f({ id: 'ridePlayer.selectRidesModal.table.waypointsShort' })}
                  <a
                    id="sort-waypoints"
                    href="#"
                    title={f({
                      id: `table.filter.${getSortType(COLUMN_WAYPOINTS).toLocaleLowerCase()}`,
                    })}
                    onClick={handleSort}
                    data-sort-column={COLUMN_WAYPOINTS}
                    data-sort-type={getSortType(COLUMN_WAYPOINTS)}
                    className={`filter cmd-icon ${getSortIconClass(
                      COLUMN_WAYPOINTS
                    )} text-decoration-none position-relative ml-2`}
                  />
                </th>
                <th
                  className="th-sticky-top text-right"
                  title={f({ id: 'ridePlayer.selectRidesModal.table.alarmsLong' })}
                >
                  {f({ id: 'ridePlayer.selectRidesModal.table.alarmsShort' })}
                  <a
                    id="sort-alarms"
                    href="#"
                    title={f({
                      id: `table.filter.${getSortType(COLUMN_ALARMS).toLocaleLowerCase()}`,
                    })}
                    onClick={handleSort}
                    data-sort-column={COLUMN_ALARMS}
                    data-sort-type={getSortType(COLUMN_ALARMS)}
                    className={`filter cmd-icon ${getSortIconClass(
                      COLUMN_ALARMS
                    )} text-decoration-none position-relative ml-2`}
                  />
                </th>
                <th
                  className="th-sticky-top text-right"
                  title={f({ id: 'ridePlayer.selectRidesModal.table.eventsLong' })}
                >
                  {f({ id: 'ridePlayer.selectRidesModal.table.eventsShort' })}
                  <a
                    id="sort-events"
                    href="#"
                    title={f({
                      id: `table.filter.${getSortType(COLUMN_EVENTS).toLocaleLowerCase()}`,
                    })}
                    onClick={handleSort}
                    data-sort-column={COLUMN_EVENTS}
                    data-sort-type={getSortType(COLUMN_EVENTS)}
                    className={`filter cmd-icon ${getSortIconClass(
                      COLUMN_EVENTS
                    )} text-decoration-none position-relative ml-2`}
                  />
                </th>
                {/* <th
                  className="th-sticky-top text-right"
                  title={f({ id: 'ridePlayer.selectRidesModal.table.waitingsLong' })}
                >
                  {f({ id: 'ridePlayer.selectRidesModal.table.waytingsShort' })}
                  <a
                    id="sort-waitings"
                    href="#"
                    title={f({
                      id: `table.filter.${getSortType(COLUMN_PAUSE).toLocaleLowerCase()}`,
                    })}
                    onClick={handleSort}
                    data-sort-column={COLUMN_PAUSE}
                    data-sort-type={getSortType(COLUMN_PAUSE)}
                    className={`filter cmd-icon ${getSortIconClass(
                      COLUMN_PAUSE
                    )} text-decoration-none position-relative ml-2`}
                  />
                </th> */}
                <th
                  className="th-sticky-top"
                  title={f({ id: 'ridePlayer.selectRidesModal.table.distance.tooltip' })}
                >
                  {f({ id: 'ridePlayer.selectRidesModal.table.distance' })}
                  <a
                    id="sort-distance"
                    href="#"
                    title={f({
                      id: `table.filter.${getSortType(COLUMN_DISTANCE).toLocaleLowerCase()}`,
                    })}
                    onClick={handleSort}
                    data-sort-column={COLUMN_DISTANCE}
                    data-sort-type={getSortType(COLUMN_DISTANCE)}
                    className={`filter cmd-icon ${getSortIconClass(
                      COLUMN_DISTANCE
                    )} text-decoration-none position-relative ml-2`}
                  />
                </th>
                <th
                  className="th-sticky-top"
                  title={f({ id: 'ridePlayer.selectRidesModal.table.duration.tooltip' })}
                >
                  {f({ id: 'ridePlayer.selectRidesModal.table.duration' })}
                  <a
                    id="sort-duration"
                    href="#"
                    title={f({
                      id: `table.filter.${getSortType(COLUMN_DURATION)}`.toLocaleLowerCase(),
                    })}
                    onClick={handleSort}
                    data-sort-column={COLUMN_DURATION}
                    data-sort-type={getSortType(COLUMN_DURATION)}
                    className={`filter cmd-icon ${getSortIconClass(
                      COLUMN_DURATION
                    )} text-decoration-none position-relative ml-2`}
                  />
                </th>
                <th
                  className="th-sticky-top"
                  title={f({ id: 'ridePlayer.selectRidesModal.table.ecoScore.tooltip' })}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {f({ id: 'ridePlayer.selectRidesModal.table.ecoScore' })}
                  <a
                    id="sort-ecoscore"
                    href="#"
                    title={f({
                      id: `table.filter.${getSortType(COLUMN_ECOSCORE)}`.toLocaleLowerCase(),
                    })}
                    onClick={handleSort}
                    data-sort-column={COLUMN_ECOSCORE}
                    data-sort-type={getSortType(COLUMN_ECOSCORE)}
                    className={`filter cmd-icon ${getSortIconClass(
                      COLUMN_ECOSCORE
                    )} text-decoration-none position-relative ml-2`}
                  />
                </th>
                <th
                  className="th-sticky-top"
                  title={f({ id: 'ridePlayer.selectRidesModal.table.driver.tooltip' })}
                >
                  {f({ id: 'ridePlayer.selectRidesModal.table.driver' })}
                  <a
                    id="sort-driver"
                    href="#"
                    title={f({
                      id: `table.filter.${getSortType(COLUMN_DRIVER)}`.toLocaleLowerCase(),
                    })}
                    onClick={handleSort}
                    data-sort-column={COLUMN_DRIVER}
                    data-sort-type={getSortType(COLUMN_DRIVER)}
                    className={`filter cmd-icon ${getSortIconClass(
                      COLUMN_DRIVER
                    )} text-decoration-none position-relative ml-2`}
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              {sortedRides.map((rideData: IRideDetail) => {
                return (
                  <tr key={`select-ride-${rideData.key}`}>
                    <td className="align-middle">
                      <div className="e-checkbox checkbox-sm">
                        <label>
                          <input
                            type="checkbox"
                            value={rideData.key}
                            onChange={handleSelect}
                            data-source={rideData.source}
                            disabled={
                              rideData.source === IS_MANUAL_RIDE ||
                              (rideData.source === IS_GPS_RIDE && props.rides.length === 1)
                            }
                            className="ride-number"
                            checked={
                              rideData.source === IS_GPS_RIDE &&
                              tempSelectedRides.includes(rideData.key)
                            }
                          />
                          <span className="p-0" />
                        </label>
                      </div>
                    </td>
                    <td className="align-middle">{rideData.key}.</td>
                    <td style={{ whiteSpace: 'nowrap' }}>
                      <span className="e-dot e-dot--sm e-dot--green" />{' '}
                      {dayjs(rideData.startDate).format('D.M.YYYY, H:mm:ss')}
                      <br />
                      <span className="e-dot e-dot--sm e-dot--red" />{' '}
                      {dayjs(rideData.endDate).format('D.M.YYYY, H:mm:ss')}
                    </td>
                    <td className="text-center">
                      <i
                        className={`cmd-icon ${
                          rideData.private
                            ? 'cmd-icon-ride-mode-private'
                            : 'cmd-icon-ride-mode-work'
                        } `}
                        title={f({
                          id: rideData.private
                            ? 'enum.ridebooks.rideTypePrivate'
                            : 'enum.ridebooks.rideTypeBusiness',
                        })}
                      />
                    </td>
                    <td
                      className="align-middle"
                      title={`${rideData.startAddress}\n${rideData.endAddress}`}
                    >
                      {rideData.startAddress}
                      <br />
                      {rideData.endAddress}
                    </td>
                    <td className="align-middle text-right">
                      <span className="text-green-dark">{rideData.totalWaypoints}</span>
                    </td>
                    <td className="align-middle text-right">
                      <span className="text-primary">{rideData.totalAlarms}</span>
                    </td>
                    <td className="align-middle text-right">
                      <span className="text-violet">{rideData.totalEvents}</span>
                    </td>
                    {/* <td className="align-middle text-right">
                      <span className="text-blue-dark">{rideData.totalPauses}</span>
                    </td> */}
                    <td className="align-middle">{rideData.totalDistance} km</td>
                    <td className="align-middle">{formatDuration(rideData.totalDuration)}</td>
                    <td className="align-middle">
                      {rideData.hasEcoScore && rideData.ecoScore && (
                        <div className="ml-1 mr-2">
                          <i
                            className={`ml-4 cmd-icon text-success ${getEcoDriveIconClass(
                              rideData.ecoScore
                            )} fs-7 align-middle`}
                            style={getEcoDriveIconColor(rideData.ecoScore)}
                          />{' '}
                          <strong style={getEcoDriveIconColor(rideData.ecoScore)}>
                            {rideData.ecoScore.toFixed(1)}
                          </strong>
                        </div>
                      )}
                    </td>
                    <td className="align-middle" title={rideData.driverName}>
                      {rideData.driverName}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </CmdModal>
  );
}
