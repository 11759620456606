import { IPermissions } from '../interfaces/permission';
import {
  TABLE_ALARM,
  TABLE_ALARM_LOG,
  TABLE_CORRECTIONS,
  TABLE_FUEL_CARD,
  TABLE_GDPR_PROFILES,
  TABLE_GDPR_PROFILES_HISTORY,
  TABLE_GDPR_USER,
  TABLE_GDPR_USER_HISTORY,
  TABLE_IMPORT_TEMPLATE,
  TABLE_ONLINE_MAP_ASSIGN,
  TABLE_OPERATING_COSTS,
  TABLE_OPERATING_COSTS_TYPES,
  TABLE_PERIOD_LOCK,
  TABLE_PERIOD_LOCK_DEFINITION,
  TABLE_PERSON,
  TABLE_REFUELING,
  TABLE_REFUELINGS_IMPORT,
  TABLE_REFUELINGS_IMPORT_RECORDS,
  TABLE_REFUELING_BLACKLIST,
  TABLE_SUPPLIER,
  TABLE_CODEBOOKS_PARKING,
  TABLE_CODEBOOKS_RIDE,
  TABLE_TEMPLATES,
  TABLE_TRIP_BOOK,
  TABLE_VEHICLE,
} from './TableService';

export const PERMISSION_PERSON = '1301';
export const PERMISSION_VEHICLE = '1401';
export const PERMISSION_RIDES = '401';
export const PERMISSION_NEW_RIDE = '426';
export const PERMISSION_IMPORTED_RECORD = '701';
export const PERMISSION_IMPORTED_FILE = '711';
export const PERMISSION_IMPORTING_TEMPLATE = '721';
export const PERMISSION_PERIOD_LOCK = '1901';
export const PERMISSION_PERIOD_LOCK_ADMIN = '1902';
export const PERMISSION_REFUELING = '601';
export const PERMISSION_CAN_REFUELING = '611';
export const PERMISSION_REFUELING_CARDS = '651';
export const PERMISSION_REFUELING_IMPORTING_BLACKLIST = '731';
export const PERMISSION_EXPENSE_TYPE = '811';
export const PERMISSION_COST = '801';
export const PERMISSION_ALARM = '1501';
export const PERMISSION_ONLINE_MAP_TEMPLATES_CUSTOMER = '1801';
export const PERMISSION_ONLINE_MAP_TEMPLATES_PRIVATE = '1802';
export const PERMISSION_ONLINE_MAP_TEMPLATES_SYSTEM = '1803';
export const PERMISSION_ONLINE_MAP_TEMPLATES_ASSIGN = '1810';
export const PERMISSION_GDPR_USER_LIST = '2001';
export const PERMISSION_GDPR_USER_CHANGE_PROFILE = '2002';
export const PERMISSION_GDPR_PROFILE_LIST = '2003';
export const PERMISSION_SUPPLIER = '821';
export const PERMISSION_TACHO_CORRECTION = '501';
export const PERMISSION_TACHO_CORRECTION_ADMIN = '502';
export const PERMISSION_TACHO_CORRECTION_NO_LIMIT = '503';
export const PERMISSION_AUTO_REPORT = '17';
export const PERMISSION_REPORT = '9';
export const PERMISSION_VEHICLE_GROUP_CUSTOMER = '1101';
export const PERMISSION_VEHICLE_GROUP_PRIVATE = '1102';
export const PERMISSION_RIDE_TAG = '1001';
export const PERMISSION_PARKING_TAG = '1002';
export const PERMISSION_MAP_SHOW_VEHICLE = '301';
export const PERMISSION_RIDE_SHOW_AND_PLAY = '413';
export const PERMISSION_RIDE_DRIVER_ASSIGNMENT = '411';
export const PERMISSION_ONLINE_MAP_TEMPLATES_ASSIGNMENT = '1811';
export const PERMISSION_ONLINE_MAP_TEMPLATES_ASSIGNMENT_DEFAULT = '1812';
export const PERMISSION_RIDE_INFO_TAGS_ASSIGMENT = '424';
export const PERMISSION_LOGIN = '101';
export const PERMISSION_WAYPOINT_CUSTOMER = '351';
export const PERMISSION_WAYPOINT_PRIVATE = '352';
export const PERMISSION_RIDE_INFO_COMMENT_ASSIGNMENT = '425';
export const PERMISSION_RIDE_POSITIONS = '414';
export const PERMISSION_RIDE_PRIVACT_SETUP = '412';
export const PERMISSION_BLUECOINS = '2101';
export const PERMISSION_BLUECOINS_USE_CREATION_SETTINGS = '2102';
export const PERMISSION_BLUECOINS_SHOW_MAP = '311';
export const PERMISSION_RIDE_INFO_EVENT = '422';
export const PERMISSION_RIDE_INFO_WAYPOINT = '421';

const PermissionsService = {
  /** Save permissions to localStorage */
  save(permissions: IPermissions): void {
    const convertedPermissions = {};
    Object.keys(permissions).forEach((customerId: string) => {
      const customerPermissions = {};
      Object.values(permissions[customerId]).forEach((value) => {
        customerPermissions[value.code] = value;
      });
      convertedPermissions[customerId] = customerPermissions;
    });
    return localStorage.setItem('permissions', JSON.stringify(convertedPermissions));
  },

  /** Get permissions from localStorage */
  get(): string | null {
    const permissions = localStorage.getItem('permissions');
    if (!permissions) {
      return null;
    }
    return JSON.parse(permissions);
  },

  getCode(resource: string): string | string[] {
    let convertedResource: string | string[] | null = null;

    switch (resource) {
      case TABLE_CODEBOOKS_PARKING:
        convertedResource = PERMISSION_PARKING_TAG;
        break;
      case TABLE_CODEBOOKS_RIDE:
        convertedResource = PERMISSION_RIDE_TAG;
        break;
      case TABLE_SUPPLIER:
        convertedResource = PERMISSION_SUPPLIER;
        break;
      case TABLE_REFUELING:
        convertedResource = PERMISSION_REFUELING;
        break;
      case TABLE_PERSON:
        convertedResource = PERMISSION_PERSON;
        break;
      case TABLE_TRIP_BOOK:
        convertedResource = PERMISSION_RIDES;
        break;
      case TABLE_CORRECTIONS:
        convertedResource = PERMISSION_TACHO_CORRECTION;
        break;
      case TABLE_REFUELINGS_IMPORT:
        convertedResource = PERMISSION_IMPORTED_FILE;
        break;
      case TABLE_REFUELINGS_IMPORT_RECORDS:
        convertedResource = PERMISSION_IMPORTED_RECORD;
        break;
      case TABLE_PERIOD_LOCK:
        convertedResource = PERMISSION_PERIOD_LOCK;
        break;
      case TABLE_PERIOD_LOCK_DEFINITION:
        convertedResource = PERMISSION_PERIOD_LOCK;
        break;
      case TABLE_FUEL_CARD:
        convertedResource = PERMISSION_REFUELING_CARDS;
        break;
      case TABLE_IMPORT_TEMPLATE:
        convertedResource = PERMISSION_IMPORTING_TEMPLATE;
        break;
      case TABLE_REFUELING_BLACKLIST:
        convertedResource = PERMISSION_REFUELING_IMPORTING_BLACKLIST;
        break;
      case TABLE_OPERATING_COSTS:
        convertedResource = PERMISSION_COST;
        break;
      case TABLE_VEHICLE:
        convertedResource = PERMISSION_VEHICLE;
        break;
      case TABLE_OPERATING_COSTS_TYPES:
        convertedResource = PERMISSION_EXPENSE_TYPE;
        break;
      case TABLE_ALARM:
        convertedResource = PERMISSION_ALARM;
        break;
      case TABLE_ALARM_LOG:
        convertedResource = PERMISSION_ALARM;
        break;
      case TABLE_TEMPLATES:
        convertedResource = [
          PERMISSION_ONLINE_MAP_TEMPLATES_CUSTOMER,
          PERMISSION_ONLINE_MAP_TEMPLATES_PRIVATE,
          PERMISSION_ONLINE_MAP_TEMPLATES_SYSTEM,
        ];
        break;
      case TABLE_ONLINE_MAP_ASSIGN:
        convertedResource = PERMISSION_ONLINE_MAP_TEMPLATES_ASSIGN;
        break;
      case TABLE_GDPR_PROFILES:
        convertedResource = PERMISSION_GDPR_PROFILE_LIST;
        break;
      case TABLE_GDPR_PROFILES_HISTORY:
        convertedResource = PERMISSION_GDPR_PROFILE_LIST;
        break;
      case TABLE_GDPR_USER:
        convertedResource = PERMISSION_GDPR_USER_LIST;
        break;
      case TABLE_GDPR_USER_HISTORY:
        convertedResource = PERMISSION_GDPR_USER_LIST;
        break;
      default:
        convertedResource = resource;
    }

    return convertedResource;
  },

  handleAccess(
    permissions: string | null,
    code: string,
    accessType: string[],
    customerId: number | null = null
  ): boolean {
    if (!permissions) {
      return false;
    }

    if (!customerId) {
      let isAllowed = false;
      Object.values(permissions).forEach((permisssion: any) => {
        if (permisssion[code]) {
          const containts = accessType.filter((type: string) =>
            permisssion[code].values.includes(type)
          );
          if (containts.length > 0) {
            isAllowed = true;
          }
        }
      });
      return isAllowed;
    }

    if (!permissions[customerId]) {
      // console.error(`Permissions error: customer not exists: ${customerId}`);
      return false;
    }

    if (!permissions[customerId][code]) {
      // console.error(`Permissions error: resource '${code}' not exists.`);
      return false;
    }

    if (!permissions[customerId][code].values) {
      // console.error('Permission error: access type not exists.');
      return false;
    }

    const containts = accessType.filter((type: string) =>
      permissions[customerId][code].values.includes(type)
    );

    if (containts.length === 0) {
      return false;
    }
    return true;
  },

  /** Check if customer has access to specified resource */
  hasAccess(
    resource: string,
    accessType: string[],
    customerId: number | number[] | null = null
  ): boolean {
    const permissions = this.get();
    const code: string | string[] = this.getCode(resource);

    let isAllowed = false;
    // If customerId is array
    if (Array.isArray(customerId)) {
      customerId.forEach((item: number) => {
        if (Array.isArray(code)) {
          code.forEach((subCode: string) => {
            if (this.handleAccess(permissions, subCode, accessType, item) && !isAllowed) {
              isAllowed = true;
            }
          });
        } else if (this.handleAccess(permissions, code, accessType, item) && !isAllowed) {
          isAllowed = true;
        }
      });
    } else if (Array.isArray(code)) {
      code.forEach((subCode: string) => {
        if (this.handleAccess(permissions, subCode, accessType, customerId)) {
          isAllowed = true;
        }
      });
      return isAllowed;
    }

    if (!Array.isArray(customerId) && !Array.isArray(code)) {
      return this.handleAccess(permissions, code, accessType, customerId);
    }
    return isAllowed;
  },
};

export default PermissionsService;
