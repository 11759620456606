/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { IVehicleOnlinePanels } from '../OnlinePanelState';
import { ICustomWidgetProps } from './Widget';
import * as OnlinePanelState from '../OnlinePanelState';

export default function LpgWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<boolean>(true);

  const vehicleId = useRecoilValue<number | false>(OnlinePanelState.isOnlinePanelOpen);
  const onlinePanelData = useRecoilValue<IVehicleOnlinePanels>(OnlinePanelState.onlinePanel);

  React.useMemo(() => {
    if (!props.isMockupMode) {
      const onlinePanel = onlinePanelData[Number(vehicleId)];

      if (onlinePanel && typeof vehicleId === 'number') {
        setWidgetData(!!onlinePanel.onLpg);
      } else {
        setWidgetData(false);
      }
    }
  }, [onlinePanelData, props.isMockupMode, vehicleId]);

  let widgetTooltip = widgetData
    ? f({ id: 'widget.lpg.tooltip.yes' })
    : f({ id: 'widget.lpg.tooltip.no' });
  if (!props.isInGrid && props.isMockupMode) {
    widgetTooltip = f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' });
  }
  if (!props.isInGrid && props.isMockupMode) {
    widgetTooltip = f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' });
  }

  const onDragStart = props.isInGrid ? undefined : props.onDragCallback;
  const unselectable = props.isInGrid ? undefined : 'on';

  return (
    <div
      data-cy="lpg-widget"
      className={`b-widget b-widget--${props.data.rowSize}x${props.data.columnSize}
      widget-grid-name-${props.data.gridName} ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={onDragStart}
      unselectable={unselectable}
      title={widgetTooltip}
      style={{ position: 'relative' }}
    >
      {props.isInGrid && (
        <a
          data-cy="lpg-widget-in-grid"
          style={{ position: 'absolute', top: '1px', right: '1px' }}
          href="#"
          title={f({ id: 'widgets.removeFromGrid.tooltip' })}
          className="cmd-icon cmd-icon-remove-sm close"
          data-grid-name={props.data.gridName}
          onClick={props.onRemoveCallback}
        />
      )}
      {props.data.columnSize === 1 && props.data.rowSize === 1 && widgetData !== null ? (
        <i
          data-cy={`lpg-widget-${widgetData && !props.isMockupMode ? 'active' : ''}-mode`}
          className={`e-widget-icon cmd-icon cmd-icon-lpg ${
            widgetData && !props.isMockupMode ? 'text-green' : ''
          }`}
        />
      ) : (
        <i className="e-widget-icon cmd-icon cmd-icon-lpg" />
      )}
    </div>
  );
}
