import * as React from 'react';
import { useIntl } from 'react-intl';

export default function Error404() {
  const { formatMessage: f } = useIntl();
  return (
    <div className="error-page ">
      <img src="/images/errors/error404.svg" alt="" />
      <div className="error-text ">
        <div className="error-text-field">
          <div className="self-stretch text-[32px] font-bold">{f({ id: 'error404.title' })}</div>
          <div className="self-stretch text-2xl font-normal">{f({ id: 'error404.subtitle' })}</div>
        </div>
        <a href="/" className="error-page-button">
          {f({ id: 'error404.btn.text' })}
        </a>
      </div>
    </div>
  );
}
