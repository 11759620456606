import * as React from 'react';
import { useIntl } from 'react-intl';
import ReactDOM from 'react-dom';
import {
  RefuelingConvertIcon,
  RefuelingIgnoreIcon,
  BinIcon,
  DuplicateSelectIcon,
} from '@commander-services/cmd-icon';
import PermissionsService, {
  PERMISSION_CAN_REFUELING,
} from '../../../../Services/PermissionsService';
import { CanRefuelingsRecord } from '../types';

interface IActionMenuProps<T extends CanRefuelingsRecord> {
  data: T;
  position: { top: number; left: number };
  ignoreCallback: () => void;
  removeCallback: () => void;
  duplicateCallback: () => void;
  convertToRefuelingCallback: () => void;
}

const IS_NEW = 'new';
const IS_CONVERTED = 'converted';

const ActionsMenu = React.forwardRef<HTMLDivElement, IActionMenuProps<CanRefuelingsRecord>>(
  (props, ref) => {
    const { formatMessage: f } = useIntl();

    const handleConvertCanRefueling = (event: React.MouseEvent<HTMLButtonElement>) => {
      props.convertToRefuelingCallback();
    };

    const handleIgnoreCanRefueling = async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      props.ignoreCallback();
    };

    const handleRemoveCanRefueling = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      props.removeCallback();
    };

    const handleDuplicateRefueling = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      props.duplicateCallback();
    };

    const menu = (
      <div
        className="ridebook-dropdown-menu dropdown-menu dropdown-menu-right dropdown-menu-custom fs-2"
        style={{
          display: 'block',
          width: '160px',
          position: 'absolute',
          top: props.position.top,
          left: props.position.left,
          zIndex: 1,
        }}
        aria-labelledby="dropdown-actions-1"
        ref={ref}
      >
        {props.data && (
          <>
            {PermissionsService.hasAccess(PERMISSION_CAN_REFUELING, ['read', 'update']) &&
              props.data.status !== IS_CONVERTED && (
                <button
                  type="button"
                  data-cy="action-convert-can-refueling"
                  title={f({ id: 'table_list.actions.convertCanRefueling' })}
                  className="px-3 py-2 d-flex align-items-center text-decoration-none text-gray text-left"
                  onClick={handleConvertCanRefueling}
                  id={`action-convert-${props.data.id}`}
                >
                  <RefuelingConvertIcon className="mr-2 fs-4" />{' '}
                  {f({ id: 'table_list.actions.convertCanRefueling' })}
                </button>
              )}
            {PermissionsService.hasAccess(PERMISSION_CAN_REFUELING, ['read', 'update']) && (
              <button
                type="button"
                data-cy="action-duplicate-refueling"
                title={
                  props.data.duplicate
                    ? f({ id: 'table_list.actions.unduplicateRefueling' })
                    : f({ id: 'table_list.actions.duplicateRefueling' })
                }
                className="px-3 py-2 d-flex align-items-center text-decoration-none text-gray text-left"
                id={`action-discard-${props.data.id}`}
                onClick={handleDuplicateRefueling}
                data-duplicate={props.data.duplicate}
              >
                <DuplicateSelectIcon className="mr-2 fs-4" />{' '}
                {props.data.duplicate
                  ? f({ id: 'table_list.actions.unduplicateRefueling' })
                  : f({ id: 'table_list.actions.duplicateRefueling' })}
              </button>
            )}
            {PermissionsService.hasAccess(PERMISSION_CAN_REFUELING, ['read', 'update']) &&
              props.data.status === IS_NEW && (
                <button
                  type="button"
                  data-cy="action-ignore-can-refueling"
                  title={f({ id: 'table_list.actions.ignoreCanRefueling' })}
                  className="px-3 py-2 d-flex align-items-center text-decoration-none text-gray text-left"
                  id={`action-activate-${props.data.id}`}
                  onClick={handleIgnoreCanRefueling}
                >
                  <RefuelingIgnoreIcon className="mr-2 fs-4" />{' '}
                  {f({ id: 'table_list.actions.ignoreCanRefueling' })}
                </button>
              )}
            {PermissionsService.hasAccess(PERMISSION_CAN_REFUELING, ['read', 'delete']) && (
              <button
                type="button"
                data-cy="action-remove-can-refueling"
                title={f({ id: 'table_list.actions.remove' })}
                className="px-3 py-2 d-flex align-items-center text-decoration-none text-gray text-left"
                id={`action-deactivate-${props.data.id}`}
                onClick={handleRemoveCanRefueling}
              >
                <BinIcon className="mr-2 fs-4" /> {f({ id: 'table_list.actions.remove' })}
              </button>
            )}
          </>
        )}
      </div>
    );

    return ReactDOM.createPortal(menu, document.body);
  }
);

export default ActionsMenu;
