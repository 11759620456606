/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { OilIcon } from '@commander-services/cmd-icon';
import { IVehicleOnlinePanels } from '../OnlinePanelState';
import { ICustomWidgetProps } from './Widget';
import * as OnlinePanelState from '../OnlinePanelState';

export default function OilLevelLowWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<boolean | null>(true);

  const vehicleId = useRecoilValue<number | false>(OnlinePanelState.isOnlinePanelOpen);
  const onlinePanelData = useRecoilValue<IVehicleOnlinePanels>(OnlinePanelState.onlinePanel);

  React.useMemo(() => {
    if (!props.isMockupMode) {
      const onlinePanel = onlinePanelData[Number(vehicleId)];

      if (
        onlinePanel &&
        typeof vehicleId === 'number' &&
        typeof onlinePanel.oilLevelLow === 'boolean'
      ) {
        setWidgetData(onlinePanel.oilLevelLow);
      } else {
        setWidgetData(null);
      }
    }
  }, [onlinePanelData, props.isMockupMode, vehicleId]);
  let widgetTooltip;

  if (widgetData === null) {
    widgetTooltip = f({ id: 'enum.onlineMap.widget.types.oilLevelLow' });
  } else if (widgetData === false) {
    widgetTooltip = f({ id: 'widget.oilLevelLow.tooltip.no' });
  } else if (widgetData === true) {
    widgetTooltip = f({ id: 'widget.oilLevelLow.tooltip.yes' });
  }

  if (!props.isInGrid && props.isMockupMode) {
    widgetTooltip = f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' });
  }

  const onDragStart = props.isInGrid ? undefined : props.onDragCallback;
  const unselectable = props.isInGrid ? undefined : 'on';

  const renderWidget = () => {
    if (widgetData === true && !props.isMockupMode) {
      return <OilIcon className="e-widget-icon" data-cy="oil-widget-active" />;
    }
    return <OilIcon className="e-widget-icon" data-cy="oil-widget-mode" color="#737373" />;
  };

  return (
    <div
      data-cy="oil-widget"
      className={`b-widget b-widget--${props.data.rowSize}x${props.data.columnSize}
      widget-grid-name-${props.data.gridName} ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={onDragStart}
      unselectable={unselectable}
      title={widgetTooltip}
      style={{ position: 'relative' }}
    >
      {props.isInGrid && (
        <a
          data-cy="oil-widget-in-grid"
          style={{ position: 'absolute', top: '1px', right: '1px' }}
          href="#"
          title={f({ id: 'widgets.removeFromGrid.tooltip' })}
          className="cmd-icon cmd-icon-remove-sm close"
          data-grid-name={props.data.gridName}
          onClick={props.onRemoveCallback}
        />
      )}
      {props.data.columnSize === 1 && props.data.rowSize === 1 && widgetData !== null ? (
        renderWidget()
      ) : (
        <OilIcon className="e-widget-icon" color="#b7b7b7" />
      )}
    </div>
  );
}
