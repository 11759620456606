import * as React from 'react';
import { useLocation, Link } from 'wouter';
import { useIntl } from 'react-intl';
import { useSetRecoilState } from 'recoil';
import { ITab } from './interfaces';
import * as TableFilter from '../../store/recoil/TableState';
import { ITableFilter } from '../../store/recoil/TableState';

interface ITabElementProps {
  data: ITab;
}

export default function TabElement(props: ITabElementProps): JSX.Element {
  const [location, setLocation] = useLocation();

  const { formatMessage: f } = useIntl();
  const setTableFilter = useSetRecoilState<ITableFilter | null>(TableFilter.setTableFilter);

  const handleOpenTab = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const url = event.currentTarget.getAttribute('data-url');
    if (url) {
      setLocation(url);
      setTableFilter(null);
    }
  };

  if (props.data.isLink) {
    if (props.data.isActive) {
      return (
        <li
          data-cy={props.data.dataCy || props.data.name.replace(/\//g, '-')}
          role="presentation"
          className="active"
        >
          <a>{props.data.name}</a>
        </li>
      );
    }
    return (
      <li data-cy={props.data.dataCy || props.data.name.replace(/\//g, '-')} role="presentation">
        <Link href={props.data.url}>{props.data.name}</Link>
      </li>
    );
  }
  return (
    <li
      key={`key-${props.data.name}`}
      id={props.data.name}
      data-cy={props.data.name.replace(/\//g, '-')}
      role="presentation"
      className={props.data.isActive ? 'active' : ''}
      onClick={handleOpenTab}
      data-url={props.data.url}
    >
      <a>{f({ id: `tab.${props.data.name}` })}</a>
    </li>
  );
}
