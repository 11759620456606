import { SocketConnectionWorker } from '../../../components/SocketConnection';
import { EVENT_IS_OPEN } from '../../../components/SocketConnection/constants';
import UserService from '../../../Services/UserService';
import * as AppState from '../AppState';

const setIsLastRidePlayerOpenToSocketServerEffect =
  () =>
  ({ onSet, getPromise }): void => {
    onSet(async (newValue: number | false) => {
      const browserTabId = await getPromise(AppState.browserTabId);
      const data = {
        event: EVENT_IS_OPEN,
        payload: ['ridePlayer', newValue],
        userId: UserService.getUserId(),
        browserTabId,
      };
      SocketConnectionWorker.postMessage(data);
    });
  };

export default setIsLastRidePlayerOpenToSocketServerEffect;
