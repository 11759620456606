/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState, useRecoilValue } from 'recoil';
import { BeltDriverIcon, EspOffIcon, EspOnIcon } from '@commander-services/cmd-icon';
import { IVehicleOnlinePanels } from '../OnlinePanelState';
import { ICustomWidgetProps } from './Widget';
import * as OnlinePanelState from '../OnlinePanelState';
import { IVehicleData, IVehicles } from '../../Vehicles/interfaces';
import * as VehiclesDataState from '../../../store/recoil/vehiclesData';
import * as VehiclesState from '../../../store/recoil/vehicles';
import { IOnlinePanelData } from '../../OnlineMapNew/PanelsLayer/interfaces';

const SEAT_BELT_DISABLED_COLOR = '#C8C8C8';
const SEAT_BELT_ON_COLOR = '#1C957A';
const SEAT_BELT_OFF_COLOR = '#B50536';

export default function SeatBeltDriverWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<boolean | null>(null);

  const vehicleId = useRecoilValue<number | false>(OnlinePanelState.isOnlinePanelOpen);
  const onlinePanelData = useRecoilValue<IVehicleOnlinePanels>(OnlinePanelState.onlinePanel);
  const vehicleData = useRecoilValue<IVehicleData | null>(
    VehiclesDataState.vehicleDataById(vehicleId)
  );

  React.useMemo(() => {
    if (!props.isMockupMode) {
      const onlinePanel = onlinePanelData[Number(vehicleId)];

      if (onlinePanel?.seatbelts && typeof vehicleId === 'number') {
        setWidgetData(onlinePanel?.seatbelts?.driver);
      } else {
        setWidgetData(null);
      }
    }
  }, [onlinePanelData, props.isMockupMode, vehicleId]);

  let widgetTooltip = f({
    id: widgetData ? 'widget.setBeltDriver.tooltip.on' : 'widget.setBeltDriver.tooltip.off',
  });
  if (!props.isInGrid && props.isMockupMode) {
    widgetTooltip = f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' });
  }
  if (!props.isInGrid && props.isMockupMode) {
    widgetTooltip = f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' });
  }

  const onDragStart = props.isInGrid ? undefined : props.onDragCallback;
  const unselectable = props.isInGrid ? undefined : 'on';

  const handleIcon = () => {
    if (props.data.columnSize === 1 && props.data.rowSize === 1) {
      if (widgetData === null) {
        return <BeltDriverIcon color={SEAT_BELT_DISABLED_COLOR} />;
      }
      if (widgetData) {
        return <BeltDriverIcon color={SEAT_BELT_ON_COLOR} />;
      }
      if (widgetData === false && onlinePanelData[Number(vehicleId)].ignition) {
        return <BeltDriverIcon color={SEAT_BELT_OFF_COLOR} />;
      }
    }

    return <BeltDriverIcon />;
  };

  return (
    <div
      data-cy="seat-belt-driver-widget"
      className={`b-widget b-widget--${props.data.rowSize}x${props.data.columnSize}
      widget-grid-name-${props.data.gridName} ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={onDragStart}
      unselectable={unselectable}
      title={widgetTooltip}
      style={{ position: 'relative' }}
    >
      {props.isInGrid && (
        <a
          data-cy="seat-belt-driver-widget-in-grid"
          style={{ position: 'absolute', top: '1px', right: '1px' }}
          href="#"
          title={f({ id: 'widgets.removeFromGrid.tooltip' })}
          className="cmd-icon cmd-icon-remove-sm close"
          data-grid-name={props.data.gridName}
          onClick={props.onRemoveCallback}
        />
      )}
      {handleIcon()}
    </div>
  );
}
