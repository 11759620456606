/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import dayjs from 'dayjs';
import { useQueryClient } from '@tanstack/react-query';
import { ICustomWidgetProps } from './Widget';
import * as OnlinePanelState from '../OnlinePanelState';
import { SIZE_1X1, SIZE_4X1, SIZE_4X2 } from './constants';
import { formatDataForWidget, formatDateString } from './WidgetService';
import { IOnlinePanelDataForWidgets } from '../../OnlineMapNew/PanelsLayer/interfaces';

const LAST_WEEK = 'LAST_WEEK';
const THIS_DAY = 'THIS_DAY';
const THIS_MONTH = 'THIS_MONTH';
const YESTERDAY = 'YESTERDAY';
const LAST_MONTH = 'LAST_MONTH';

const mockRideBookData = {
  today: {
    duration: '45m 30s',
    distance: 35,
  },
  yesterday: {
    duration: '1h 37m 30s',
    distance: 43.63,
  },
  '7days': {
    duration: '8h 47m 39s',
    distance: 369.71,
  },
  currentMonth: {
    duration: '21h 5m 55s',
    distance: 905.85,
  },
  prevMonth: {
    duration: '1d 7h 28m 8s',
    distance: 1743.23,
  },
};

export default function RideBookWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const vehicleId = useRecoilValue<number | false>(OnlinePanelState.isOnlinePanelOpen);
  const onDragStart = props.isInGrid ? undefined : props.onDragCallback;
  const unselectable = props.isInGrid ? undefined : 'on';
  const queryClient = useQueryClient();
  const rideBookData: IOnlinePanelDataForWidgets | undefined = queryClient.getQueryData([
    'widgetsDataForOnlinePanel',
    vehicleId,
  ]);

  const handleOnClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    const dateKey = event.currentTarget.getAttribute('data-id');

    let dateInterval = [formatDateString(new Date()), formatDateString(new Date())];

    switch (dateKey) {
      case THIS_DAY: {
        dateInterval = [formatDateString(new Date()), formatDateString(new Date())];
        break;
      }
      case YESTERDAY: {
        dateInterval = [
          formatDateString(dayjs().subtract(1, 'day').toDate()),
          formatDateString(dayjs().subtract(1, 'day').toDate()),
        ];
        break;
      }
      case LAST_WEEK: {
        const today = dayjs();
        const lastWeek = today.subtract(6, 'days').toDate();
        dateInterval = [formatDateString(lastWeek), formatDateString(today.toDate())];
        break;
      }
      case THIS_MONTH: {
        dateInterval = [
          formatDateString(new Date(new Date().setDate(1))),
          formatDateString(new Date()),
        ];
        break;
      }
      case LAST_MONTH: {
        const lastMonthFirstDay = new Date();
        lastMonthFirstDay.setMonth(lastMonthFirstDay.getMonth() - 1, 1);
        const lastMonthLastDay = new Date();
        lastMonthLastDay.setMonth(lastMonthLastDay.getMonth(), 0);

        dateInterval = [formatDateString(lastMonthFirstDay), formatDateString(lastMonthLastDay)];
        break;
      }

      default:
        break;
    }

    if (dateKey) {
      // Base URL without query parameters
      const urlString = `/overview-tours/${vehicleId}`;

      // Create a URL object
      const url = new URL(urlString, window.location.origin);

      // Check if dateKey is an array
      if (Array.isArray(dateInterval) && dateInterval.length > 0) {
        // Join the dates with a separator (e.g., comma) and add as a single query parameter
        const dateSegment = dateInterval.join(',');
        url.searchParams.append('date', dateSegment);
      } else if (typeof dateInterval === 'string' && dateInterval) {
        // Handle a single date string and add as a query parameter
        url.searchParams.append('date', dateInterval);
      } // If dateKey is empty or invalid, no 'date' parameter is added

      // Open the URL
      window.open(url.toString(), '_blank');
    }
  };

  const handleWidgetTooltip = () => {
    if (props.data.columnSize === 1 && props.data.rowSize === 1 && !props.isMockupMode) {
      return f({ id: 'widget.rideBook.tooltip.lastWeek' });
    }
    if (!props.isInGrid && props.isMockupMode) {
      return f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' });
    }
    return '';
  };

  const getTitle = (id, data) => {
    const formattedData = formatDataForWidget(data);
    return formattedData !== '—' ? `${f({ id })}: ${formattedData}` : '';
  };

  const renderInfoBlock = (dataId, datepickerId, data) => {
    const formattedData = formatDataForWidget(data);
    return (
      <div
        className="info"
        onClick={props.isMockupMode || formattedData === '—' ? undefined : handleOnClick}
        data-id={dataId}
        title={getTitle(datepickerId, data)}
      >
        <div className="text-truncate">
          <strong>{`${f({ id: datepickerId })}:`}</strong>{' '}
          {formattedData !== '—' ? (
            <a href="#">
              <span>{formattedData}</span>
            </a>
          ) : (
            <span>—</span>
          )}
        </div>
      </div>
    );
  };

  const handleClickAbleWidget = (size: string) => {
    if (size === SIZE_1X1) {
      return 'is-clickable';
    }
    if (!Array.isArray(rideBookData?.ride_overview)) {
      if (
        (size === SIZE_4X1 &&
          formatDataForWidget(rideBookData?.ride_overview?.currentMonth) !== '—') ||
        formatDataForWidget(rideBookData?.ride_overview?.prevMonth) !== '—' ||
        formatDataForWidget(rideBookData?.ride_overview?.['7days']) !== '—'
      ) {
        return 'is-clickable';
      }
      if (
        (size === SIZE_4X2 &&
          formatDataForWidget(rideBookData?.ride_overview?.currentMonth) !== '—') ||
        formatDataForWidget(rideBookData?.ride_overview?.prevMonth) !== '—' ||
        formatDataForWidget(rideBookData?.ride_overview?.['7days']) !== '—' ||
        formatDataForWidget(rideBookData?.ride_overview?.today) !== '—' ||
        formatDataForWidget(rideBookData?.ride_overview?.yesterday) !== '—'
      ) {
        return 'is-clickable';
      }
    }
    return '';
  };

  return (
    <div
      data-cy="ride-book-widget"
      className={`b-widget ${handleClickAbleWidget(props.data.size)} b-widget--${
        props.data.rowSize
      }x${props.data.columnSize}
      widget-grid-name-${props.data.gridName} ${
        props.data.hasIcon && props.data.columnSize > 1
          ? 'b-widget--alignLeft b-widget--carInfo'
          : ''
      } ${props.data.rowSize === 2 ? ' b-widget--2rows' : ''} widget-grid-name-${
        props.data.gridName
      } ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={onDragStart}
      unselectable={unselectable}
      title={handleWidgetTooltip()}
      style={{
        position: 'relative',
        cursor: `${handleClickAbleWidget(props.data.size) ? 'pointer' : 'default'}`,
      }}
      onClick={
        props.data.columnSize === 1 && props.data.rowSize === 1 && !props.isMockupMode
          ? handleOnClick
          : undefined
      }
      data-id={LAST_WEEK}
    >
      {props.isInGrid && (
        <a
          data-cy="ride-book-widget-in-grid"
          style={{
            position: 'absolute',
            top: '1px',
            right: '1px',
            left: 'inherit',
            transform: 'inherit',
          }}
          href="#"
          title={f({ id: 'widgets.removeFromGrid.tooltip' })}
          className="cmd-icon cmd-icon-remove-sm close"
          data-grid-name={props.data.gridName}
          onClick={props.onRemoveCallback}
        />
      )}
      {props.data.size === SIZE_1X1 && (
        <i
          data-cy="ride-book-widget-icon-size-1-row-1"
          className="e-widget-icon cmd-icon cmd-icon-driving-overview"
        />
      )}
      {props.data.size === SIZE_4X1 && (
        <>
          <i
            data-cy="ride-book-widget-icon-size-4-row-1"
            className="e-widget-icon cmd-icon cmd-icon-driving-overview"
          />
          {renderInfoBlock(
            THIS_DAY,
            'datepicker.TODAY',
            props.isMockupMode || Array.isArray(rideBookData?.ride_overview)
              ? mockRideBookData.today
              : rideBookData?.ride_overview?.today
          )}
          {renderInfoBlock(
            THIS_MONTH,
            'datepicker.THIS_MONTH',
            props.isMockupMode || Array.isArray(rideBookData?.ride_overview)
              ? mockRideBookData.currentMonth
              : rideBookData?.ride_overview?.currentMonth
          )}
          {renderInfoBlock(
            LAST_MONTH,
            'date.prevMonth.shortcut',
            props.isMockupMode || Array.isArray(rideBookData?.ride_overview)
              ? mockRideBookData.prevMonth
              : rideBookData?.ride_overview?.prevMonth
          )}
        </>
      )}
      {props.data.size === SIZE_4X2 && (
        <>
          <i
            data-cy="ride-book-widget-icon-size-4-row-2"
            className="e-widget-icon cmd-icon cmd-icon-driving-overview"
          />
          {renderInfoBlock(
            THIS_DAY,
            'datepicker.TODAY',
            props.isMockupMode || Array.isArray(rideBookData?.ride_overview)
              ? mockRideBookData.today
              : rideBookData?.ride_overview?.today
          )}
          {renderInfoBlock(
            YESTERDAY,
            'datepicker.YESTERDAY',
            props.isMockupMode || Array.isArray(rideBookData?.ride_overview)
              ? mockRideBookData.yesterday
              : rideBookData?.ride_overview?.yesterday
          )}
          {renderInfoBlock(
            LAST_WEEK,
            'datepicker.LAST_WEEK',
            props.isMockupMode ? mockRideBookData['7days'] : rideBookData?.ride_overview?.['7days']
          )}
          {renderInfoBlock(
            THIS_MONTH,
            'datepicker.THIS_MONTH',
            props.isMockupMode || Array.isArray(rideBookData?.ride_overview)
              ? mockRideBookData.currentMonth
              : rideBookData?.ride_overview?.currentMonth
          )}
          {renderInfoBlock(
            LAST_MONTH,
            'date.prevMonth.shortcut',
            props.isMockupMode || Array.isArray(rideBookData?.ride_overview)
              ? mockRideBookData.prevMonth
              : rideBookData?.ride_overview?.prevMonth
          )}
        </>
      )}
    </div>
  );
}
