import localforage from 'localforage';
import { atom } from 'recoil';
import localForageEffect from '../../store/recoil/effects/localForageEffect';

export const getIsNavigatorOpenFromIndexedDb = async (): Promise<boolean> => {
  const isNavigatorOpen = await localforage.getItem<boolean>('isNavigatorOpen');
  if (isNavigatorOpen === null) {
    return true;
  }
  return isNavigatorOpen;
};

export const filterString = atom<string>({
  key: 'filterString',
  default: '',
});

export const filteredVehicles = atom<number[]>({
  key: 'filteredVehicles',
  default: [],
});

export const isOpen = atom<boolean>({
  key: 'isOpen',
  default: getIsNavigatorOpenFromIndexedDb(),
  effects: [localForageEffect('isNavigatorOpen')],
});

export const isDisabled = atom<boolean>({
  key: 'isDisabled',
  default: false,
});

export const showLoader = atom<boolean>({
  key: 'showLoader',
  default: false,
});

export const showVehicles = atom<boolean>({
  key: 'showVehicles',
  default: true,
});

export const showVehicleGroups = atom<boolean>({
  key: 'showVehicleGroups',
  default: false,
});
export const isMinimalizedCarsIcons = atom<boolean>({
  key: 'isMinimalizedCarsIcons',
  default: false,
});
