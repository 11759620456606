/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { IVehicleOnlinePanels } from '../OnlinePanelState';
import { ICustomWidgetProps } from './Widget';
import * as WidgetService from '../WidgetService';
import * as OnlinePanelState from '../OnlinePanelState';

export default function AltitudeWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<number | null>(256);
  const valueTypeShort = f({ id: 'widget.altitude.valueType.short' });
  const valueTypeLong = f({ id: 'widget.altitude.valueType.long' });

  const vehicleId = useRecoilValue<number | false>(OnlinePanelState.isOnlinePanelOpen);
  const onlinePanelData = useRecoilValue<IVehicleOnlinePanels>(OnlinePanelState.onlinePanel);
  const shortName = f({ id: 'widget.altitude.valueName.short' });
  const longName = f({ id: 'widget.altitude.valueName.long' });

  React.useMemo(() => {
    if (!props.isMockupMode) {
      const onlinePanel = onlinePanelData[Number(vehicleId)];

      if (onlinePanel && onlinePanel.altitude !== null) {
        const roundedValue = Number(onlinePanel.altitude).toFixed(2);
        setWidgetData(Number(roundedValue));
      } else {
        setWidgetData(null);
      }
    }
  }, [onlinePanelData, props.isMockupMode, vehicleId]);

  return (
    <div
      data-cy="altitude-widget-online-panel"
      className={`b-widget b-widget--${props.data.rowSize}x${props.data.columnSize} ${
        props.data.hasIcon && props.data.columnSize > 1 ? 'b-widget--alignLeft' : ''
      } widget-grid-name-${props.data.gridName} ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={props.isInGrid ? undefined : props.onDragCallback}
      unselectable={props.isInGrid ? undefined : 'on'}
      title={WidgetService.getTooltip(
        f({ id: 'widget.altitude.tooltip' }),
        f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' }),
        props.isInGrid,
        props.isMockupMode
      )}
    >
      {props.isInGrid && (
        <a
          style={{
            position: 'absolute',
            top: '1px',
            right: '1px',
            left: 'inherit',
            transform: 'inherit',
          }}
          href="#"
          title={f({ id: 'widgets.removeFromGrid.tooltip' })}
          className="cmd-icon cmd-icon-remove-sm close"
          data-grid-name={props.data.gridName}
          onClick={props.onRemoveCallback}
          data-cy="altitude-widget-in-grid"
        />
      )}
      {props.data.hasTitle && props.data.columnSize === 1 && !props.data.hasIcon && (
        <h4 data-cy="altitude-widget-title-size-1" className="e-widget-title">
          {shortName}
        </h4>
      )}
      {props.data.hasTitle && props.data.columnSize > 1 && !props.data.hasIcon && (
        <h4 data-cy="altitude-widget-title-size-more-1" className="e-widget-title">
          {shortName}
        </h4>
      )}
      {props.data.hasTitle && props.data.columnSize === 2 && (
        <h4 data-cy="altitude-widget-title-size-2" className="e-widget-title">
          {shortName}
        </h4>
      )}
      {props.data.hasIcon && props.data.columnSize > 1 && (
        <div data-cy="altitude-widget-icon-size-more-1" className="row no-gutters">
          <div className="col-auto">
            {props.data.columnSize === 2 && (
              <i
                className={`e-widget-icon cmd-icon cmd-icon-${
                  props.data.icon
                } ${WidgetService.isDisabled(widgetData)}`}
              />
            )}
            {props.data.columnSize === 3 && (
              <i
                className={`e-widget-icon e-widget-icon--md cmd-icon cmd-icon-${
                  props.data.icon
                } ${WidgetService.isDisabled(widgetData)}`}
              />
            )}
          </div>
          <div className="col">
            {props.data.hasTitle && props.data.columnSize === 3 && (
              <h4 className="e-widget-title">{longName}</h4>
            )}
            <div
              className={`e-widget-value e-widget-value--md ${WidgetService.isDisabled(
                widgetData
              )}`}
            >
              {props.data.columnSize === 2 &&
                WidgetService.getValue(widgetData, props.data.hasUnitType, valueTypeShort)}
              {props.data.columnSize === 3 &&
                WidgetService.getValue(widgetData, props.data.hasUnitType, valueTypeLong)}
            </div>
          </div>
        </div>
      )}
      {!props.data.hasIcon && props.data.columnSize > 1 && (
        <div
          data-cy="altitude-widget-value-size-more-1"
          className={`e-widget-value e-widget-value--md ${WidgetService.isDisabled(widgetData)}`}
        >
          {props.data.columnSize === 2 &&
            WidgetService.getValue(widgetData, props.data.hasUnitType, valueTypeShort)}
          {props.data.columnSize === 3 &&
            WidgetService.getValue(widgetData, props.data.hasUnitType, valueTypeLong)}
        </div>
      )}
      {props.data.hasIcon && props.data.columnSize === 1 && (
        <i
          data-cy="altitude-widget-icon-size-1"
          className={`e-widget-icon cmd-icon cmd-icon-${props.data.icon} ${WidgetService.isDisabled(
            widgetData
          )}`}
        />
      )}
      {props.data.columnSize === 1 && (
        <div
          data-cy="altitude-widget-value-size-1"
          className={`e-widget-value ${WidgetService.isDisabled(widgetData)}`}
        >
          {WidgetService.getValue(widgetData, props.data.hasUnitType, valueTypeShort)}
        </div>
      )}
    </div>
  );
}
