import { CmdToast } from '@commander-services/gui-components';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import * as ApplicationUpdateService from './ApplicationUpdateService';
import * as AppState from '../../store/recoil/AppState';

export default function ApplicationUpdate(): JSX.Element | null {
  const { formatMessage: f } = useIntl();
  const [ignoreUpdate, setIgnoreUpdate] = React.useState<boolean | null>(null);
  const [appVersion, setAppVersion] = React.useState<string | null>(null);
  const [showToast, setShowToast] = React.useState<boolean>(false);
  const onPageWithForm = useRecoilValue<boolean>(AppState.onPageWithForm);

  const handleIgnoreUpdate = () => {
    setIgnoreUpdate(true);
  };

  const handleUpdate = async () => {
    console.info('Application update accepted.');
    const cachesKeys = await window.caches.keys();
    cachesKeys.forEach((key: string) => {
      window.caches.delete(key);
    });
    window.location.reload();
  };

  React.useEffect(() => {
    const fetchCacheTimeout = setInterval(async () => {
      const version = await ApplicationUpdateService.getVersion();
      setAppVersion(version);
    }, 60000);
    return () => {
      clearInterval(fetchCacheTimeout);
    };
  });

  // When mounted - set application version from meta.json
  React.useEffect(() => {
    const getVersion = async () => {
      const version = await ApplicationUpdateService.getVersion();
      if (version) {
        console.info('New version released: ', version);
        console.info('Old version is: ', appVersion);
        ApplicationUpdateService.setVersion(version);
        setAppVersion(version);
      } else {
        console.warn('ApplicationUpdate: Cannot set application version.');
      }
    };
    getVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (
      appVersion !== null &&
      !ApplicationUpdateService.isLatestVersion(appVersion) &&
      !onPageWithForm
    ) {
      // setShowToast(true);
      handleUpdate();
    }
  }, [appVersion, onPageWithForm]);

  if (showToast && !ignoreUpdate) {
    return (
      <CmdToast
        id="app-update-toast"
        title={f({ id: 'appVersionUpdate.toast.title' })}
        type="info"
        onClose={handleIgnoreUpdate}
        duration={null}
        width="auto"
      >
        {f({ id: 'appVersionUpdate.toast.text' })}{' '}
        <a href="#" className="text-bold" onClick={handleUpdate}>
          {f({ id: 'appVersionUpdate.toast.button.update' })}
        </a>
      </CmdToast>
    );
  }
  return null;
}
