import { atom, selector } from 'recoil';
import {
  IDetailWaypointResponseData,
  IFiltersWaypoint,
  IWaypoint,
  IWaypointPostion,
} from './interfaces';
import Config from '../../../../config';
import { bounds, isClusterEnabled, minPoints, zoom } from '../OnlineMapState';
import { SuperclusterWaypointWorker } from '../CreateWorkers';

export const waypoints = atom<IWaypoint[]>({
  key: 'waypoints',
  default: [],
});
export const detailWaypoint = atom<IDetailWaypointResponseData | null>({
  key: 'detailWaypoint',
  default: null,
});
export const activeFilter = atom<IFiltersWaypoint | null>({
  key: 'activeFilter',
  default: null,
});

export const waypointsLoaded = atom<boolean>({
  key: 'waypointsLoaded',
  default: false,
});

export const isWaypointsOpen = atom<boolean>({
  key: 'isWaypointsOpen',
  default: false,
});

export const selectedWaypointId = atom<number | null>({
  key: 'selectedWaypointId',
  default: null,
});
export const computedArea = atom<number>({
  key: 'computedArea',
  default: 0,
});

export const waypointData = atom<IDetailWaypointResponseData | null>({
  key: 'waypointData',
  default: null,
});

export const showWaypointLoader = atom<boolean>({
  key: 'showWaypointLoader',
  default: false,
});

export const filteredWaypointsString = atom<string>({
  key: 'filteredWaypointsString',
  default: '',
});

export const filteredWaypoints = atom<IWaypoint[]>({
  key: 'filteredWaypoints',
  default: [],
});

export const onHoverWaypointId = atom<number | null>({
  key: 'onHoverWaypointId',
  default: null,
});

export const removedWaypointId = atom<number | null>({
  key: 'removedWaypointId',
  default: null,
});

export const canEditWaypoint = atom<boolean>({
  key: 'canEditWaypoint',
  default: false,
});

export const canDrawWaypoint = atom<boolean>({
  key: 'canDrawWaypoint',
  default: false,
});
export const isCopyModeWp = atom<boolean>({
  key: 'isCopyModeWp',
  default: false,
});

export const waypointsData = selector<IWaypoint[]>({
  key: 'waypointsData',
  get: ({ get }) => {
    let waypointsDataWorker = get(waypoints);
    const zoomData = get(zoom);
    const boundsData = get(bounds);
    const filteredWaypointsStringWorker = get(filteredWaypointsString);
    const filteredWaypointsWorker = get(filteredWaypoints);
    if (filteredWaypointsStringWorker.length > 0) {
      waypointsDataWorker = filteredWaypointsWorker;
    }

    SuperclusterWaypointWorker.postMessage({
      waypointsData: waypointsDataWorker,
      bounds: boundsData,
      zoom: zoomData,
      minPoints: Config.onlineMap.superCluster.minPoints,
    });
    return waypointsDataWorker;
  },
});

export const waypointId = atom<number | null>({
  key: 'waypointId',
  default: null,
});
export const coordinates = atom<IWaypointPostion[] | null>({
  key: 'coordinates',
  default: null,
});
export const centerWaypoint = atom<IWaypointPostion | null>({
  key: 'centerWaypoint',
  default: null,
});
export const rawCoordinates = atom<google.maps.LatLng[] | null>({
  key: 'rawCoordinates',
  default: null,
});
export const isEditMode = atom<boolean>({
  key: 'isEditMode',
  default: false,
});
export const isDrawWaypoint = atom<boolean>({
  key: 'isDrawWaypoint',
  default: false,
});

export const isMinimalizedWp = atom<boolean>({
  key: 'isMinimalizedWp',
  default: false,
});
