/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { EspOffIcon, EspOnIcon, EspDisabledIcon } from '@commander-services/cmd-icon';
import { IVehicleOnlinePanels } from '../OnlinePanelState';
import { ICustomWidgetProps } from './Widget';
import * as OnlinePanelState from '../OnlinePanelState';

const ESP_DISABLED_COLOR = '#C8C8C8';

export default function EspWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<boolean | null>(true);

  const vehicleId = useRecoilValue<number | false>(OnlinePanelState.isOnlinePanelOpen);
  const onlinePanelData = useRecoilValue<IVehicleOnlinePanels>(OnlinePanelState.onlinePanel);

  React.useMemo(() => {
    if (!props.isMockupMode) {
      const onlinePanel = onlinePanelData[Number(vehicleId)];

      if (onlinePanel && typeof onlinePanel.esp === 'boolean' && typeof vehicleId === 'number') {
        setWidgetData(onlinePanel.esp);
      } else {
        setWidgetData(null);
      }
    }
  }, [onlinePanelData, props.isMockupMode, vehicleId]);

  let widgetTooltip;

  if (widgetData === null) {
    widgetTooltip = f({ id: 'enum.onlineMap.widget.types.esp' });
  } else if (widgetData === false) {
    widgetTooltip = f({ id: 'widget.esp.tooltip.on' });
  } else if (widgetData === true) {
    widgetTooltip = f({ id: 'widget.esp.tooltip.off' });
  }

  if (!props.isInGrid && props.isMockupMode) {
    widgetTooltip = f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' });
  }

  const onDragStart = props.isInGrid ? undefined : props.onDragCallback;
  const unselectable = props.isInGrid ? undefined : 'on';

  const handleIcon = () => {
    if (props.data.columnSize === 1 && props.data.rowSize === 1 && widgetData === null) {
      return <EspDisabledIcon />;
    }
    if (props.data.columnSize === 1 && props.data.rowSize === 1 && widgetData === true)
      return <EspOffIcon />;

    return <EspOnIcon />;
  };

  return (
    <div
      data-cy="locked-widget"
      className={`b-widget b-widget--${props.data.rowSize}x${props.data.columnSize}
      widget-grid-name-${props.data.gridName} ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={onDragStart}
      unselectable={unselectable}
      title={widgetTooltip}
      style={{ position: 'relative' }}
    >
      {props.isInGrid && (
        <a
          data-cy="locked-widget-in-grid"
          style={{ position: 'absolute', top: '1px', right: '1px' }}
          href="#"
          title={f({ id: 'widgets.removeFromGrid.tooltip' })}
          className="cmd-icon cmd-icon-remove-sm close"
          data-grid-name={props.data.gridName}
          onClick={props.onRemoveCallback}
        />
      )}
      {handleIcon()}
    </div>
  );
}
