import { atom } from 'recoil';
import setIsGraphOpenToSocketServerEffect from '../../store/recoil/effects/setIsGraphOpenToSocketServerEffect';
import { IWidgetGraph } from '../OnlinePanel/OnlinePanelState';
import { IGraphCode } from './GraphService';

export interface IGraphCurrentPointsData {
  timestamp: string;
  address: string;
  code: string;
}

export const isGraphOpen = atom<number | boolean>({
  key: 'isGraphOpen',
  default: false,
  effects: [setIsGraphOpenToSocketServerEffect()],
});

export const ecoDriveWidgetGraph = atom<IWidgetGraph | null>({
  key: 'ecoDriveWidgetGraph',
  default: null,
});

export const tooltipCode = atom<IGraphCode | null>({
  key: 'tooltipCode',
  default: null,
});

export const lastClickedTimestamp = atom<string>({
  key: 'lastClickedGraphTimestamp',
  default: '',
});

export const graphCurrentPointsData = atom<IGraphCurrentPointsData[] | null>({
  key: 'graphCurrentPointsData',
  default: null,
});

export const speedWidgetGraph = atom<IWidgetGraph | null>({
  key: 'speedWidgetGraph',
  default: null,
});

export const widgetGraphSelectedValues = atom<string[] | null>({
  key: 'widgetGraphSelectedValues',
  default: null,
});

export const throttleWidgetGraph = atom<IWidgetGraph | null>({
  key: 'throttleWidgetGraph',
  default: null,
});

export const fuelLevelPercWidgetGraph = atom<IWidgetGraph | null>({
  key: 'fuelLevelPercWidgetGraph',
  default: null,
});

export const fuelLevelLitersWidgetGraph = atom<IWidgetGraph | null>({
  key: 'fuelLevelLitersWidgetGraph',
  default: null,
});

export const ordinaryWidgetGraph = atom<IWidgetGraph | null>({
  key: 'ordinaryWidgetGraph',
  default: null,
});

export const lastGraphReloadTimestamp = atom<Date | null>({
  key: 'lastGraphReloadTimestamp',
  default: null,
});

export const evBatteryKwWidgetGraph = atom<IWidgetGraph | null>({
  key: 'evBatteryKwWidgetGraph',
  default: null,
});
export const evBatteryPercWidgetGraph = atom<IWidgetGraph | null>({
  key: 'evBatteryPercWidgetGraph',
  default: null,
});
export const adBluePercWidgetGraph = atom<IWidgetGraph | null>({
  key: 'adBluePercWidgetGraph',
  default: null,
});
