/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  BackSeatLeftBeltIcon,
  BeltBackSeatMiddleIcon,
  BeltBackSeatRightIcon,
} from '@commander-services/cmd-icon';
import { IVehicleOnlinePanels } from '../OnlinePanelState';
import { ICustomWidgetProps } from './Widget';
import * as OnlinePanelState from '../OnlinePanelState';

const SEAT_BELT_DISABLED_COLOR = '#C8C8C8';
const SEAT_BELT_ON_COLOR = '#1C957A';

export default function SeatBeltBackWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<{
    leftPassenger: boolean;
    rightPassenger: boolean;
    centrePassenger: boolean;
  } | null>(null);

  const vehicleId = useRecoilValue<number | false>(OnlinePanelState.isOnlinePanelOpen);
  const onlinePanelData = useRecoilValue<IVehicleOnlinePanels>(OnlinePanelState.onlinePanel);

  React.useMemo(() => {
    if (!props.isMockupMode) {
      const onlinePanel = onlinePanelData[Number(vehicleId)];

      if (onlinePanel?.seatbelts && typeof vehicleId === 'number') {
        setWidgetData({
          leftPassenger: onlinePanel.seatbelts.leftPassenger,
          rightPassenger: onlinePanel.seatbelts.rightPassenger,
          centrePassenger: onlinePanel.seatbelts.centrePassenger,
        });
      } else {
        setWidgetData(null);
      }
    }
  }, [onlinePanelData, props.isMockupMode, vehicleId]);

  const onDragStart = props.isInGrid ? undefined : props.onDragCallback;
  const unselectable = props.isInGrid ? undefined : 'on';

  // Function to handle seat belt icon rendering
  const renderSeatBeltIcon = (
    seatbeltStatus: boolean | null,
    IconComponent: React.ElementType
  ): JSX.Element => {
    if (props.data.columnSize === 2 && props.data.rowSize === 1) {
      if (seatbeltStatus === null) {
        return <IconComponent color={SEAT_BELT_DISABLED_COLOR} />;
      }
      if (seatbeltStatus) {
        return <IconComponent color={SEAT_BELT_ON_COLOR} />;
      }
    }

    return <IconComponent />;
  };

  // Define specific tooltip messages for each seat
  const leftSeatTooltip = f({
    id: widgetData?.leftPassenger
      ? 'widget.leftSeatBelt.tooltip.on'
      : 'widget.leftSeatBelt.tooltip.off',
  });

  const centerSeatTooltip = f({
    id: widgetData?.centrePassenger
      ? 'widget.centreSeatBelt.tooltip.on'
      : 'widget.centreSeatBelt.tooltip.off',
  });

  const rightSeatTooltip = f({
    id: widgetData?.rightPassenger
      ? 'widget.rightSeatBelt.tooltip.on'
      : 'widget.rightSeatBelt.tooltip.off',
  });

  return (
    <div
      data-cy="seat-belt-back-seat-widget"
      className={`p-0 b-widget b-widget--${props.data.rowSize}x${props.data.columnSize}
      widget-grid-name-${props.data.gridName} ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={onDragStart}
      unselectable={unselectable}
      style={{ position: 'relative' }}
    >
      {props.isInGrid && (
        <a
          data-cy="seat-belt-back-seat-widget-in-grid"
          style={{ position: 'absolute', top: '1px', right: '1px' }}
          href="#"
          title={f({ id: 'widgets.removeFromGrid.tooltip' })}
          className="cmd-icon cmd-icon-remove-sm close"
          data-grid-name={props.data.gridName}
          onClick={props.onRemoveCallback}
        />
      )}
      <div className="backSeatBeltContainer-widget">
        <div className="passengerIcon-widget" title={rightSeatTooltip}>
          {renderSeatBeltIcon(widgetData?.leftPassenger ?? null, BackSeatLeftBeltIcon)}
        </div>
        <div className="passengerIcon-widget" title={centerSeatTooltip}>
          {renderSeatBeltIcon(widgetData?.centrePassenger ?? null, BeltBackSeatMiddleIcon)}
        </div>
        <div className="passengerIcon-widget" title={leftSeatTooltip}>
          {renderSeatBeltIcon(widgetData?.rightPassenger ?? null, BeltBackSeatRightIcon)}
        </div>
      </div>
    </div>
  );
}
