import { SocketConnectionWorker } from '../../../components/SocketConnection';
import { EVENT_GDPR_SETTINGS } from '../../../components/SocketConnection/constants';
import { IGdprMapSettings } from '../../../components/SocketConnection/interfaces';
import { getGdprMapSettings } from '../../../components/SocketConnection/SocketConnectionService';
import UserService from '../../../Services/UserService';

export const setGdprSettingsToSocketServerEffect =
  () =>
  ({ onSet }): void => {
    onSet((newValue: any[]) => {
      const value: IGdprMapSettings[] = [];
      newValue.forEach((profile) => {
        if (profile.authMap && profile.authMap[0]) {
          value.push({ customerId: profile.customerId, authMap: profile.authMap[0].enabled });
        }
      });
      const userId = UserService.getUserId();
      const driverId = UserService.getDriverId();
      const data = {
        event: EVENT_GDPR_SETTINGS,
        payload: getGdprMapSettings(newValue, userId, driverId),
        userId,
      };
      SocketConnectionWorker.postMessage(data);
    });
  };

export default setGdprSettingsToSocketServerEffect;
