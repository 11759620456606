import { atom, selectorFamily } from 'recoil';
import { IVehicleData, IVehiclesData } from '../../components/Vehicles/interfaces';

export const vehiclesData = atom<IVehiclesData>({
  key: 'vehiclesData',
  default: {},
});

export const vehicleDataById = selectorFamily<IVehicleData | null, number | false>({
  key: 'vehicleDataById',
  get:
    (vehicleId: number | false) =>
    ({ get }) => {
      if (!vehicleId) {
        return null;
      }
      const vehicles = get(vehiclesData);
      return vehicles[vehicleId];
    },
});
