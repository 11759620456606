/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { useIntl } from 'react-intl';
import { ICustomWidgetProps } from './Widget';

export default function TachowebWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();

  const onDragStart = props.isInGrid ? undefined : props.onDragCallback;
  const unselectable = props.isInGrid ? undefined : 'on';

  const handleOnClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    const url = `https://ddd.teltonika.lt/Account/Login`;
    window.open(url, '_blank');
  };

  const handleWidgetTooltip = () => {
    if (props.data.columnSize === 1 && props.data.rowSize === 1 && !props.isMockupMode) {
      return f({ id: 'widget.tachoweb.tooltip' });
    }
    if (!props.isInGrid && props.isMockupMode) {
      return f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' });
    }
    return '';
  };

  return (
    <div
      data-cy="tachoweb-widget"
      className={`b-widget is-clickable b-widget--${props.data.rowSize}x${props.data.columnSize}
      widget-grid-name-${props.data.gridName} ${props.isInGrid ? '' : 'droppable-element'}`}
      draggable={!props.isInGrid}
      onDragStart={onDragStart}
      unselectable={unselectable}
      title={handleWidgetTooltip()}
      style={{ position: 'relative', cursor: 'pointer' }}
      onClick={
        props.data.columnSize === 1 && props.data.rowSize === 1 && !props.isMockupMode
          ? handleOnClick
          : undefined
      }
    >
      {props.isInGrid && (
        <a
          data-cy="tachoweb-widget-in-grid"
          style={{ position: 'absolute', top: '1px', right: '1px' }}
          href="#"
          title={f({ id: 'widgets.removeFromGrid.tooltip' })}
          className="cmd-icon cmd-icon-remove-sm close"
          data-grid-name={props.data.gridName}
          onClick={props.onRemoveCallback}
        />
      )}
      {props.data.columnSize === 1 && props.data.rowSize === 1 && (
        <>
          <i
            data-cy="tachoweb-widget-icon-size-1"
            className="e-widget-icon cmd-icon cmd-icon-tachograph"
          />
          <strong style={{ marginTop: '1rem' }}>Tachoweb</strong>
        </>
      )}
    </div>
  );
}
