import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { useIntl } from 'react-intl';

interface IConfirmDialogProps {
  cancelCallback: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | KeyboardEvent) => void;
  continueCallback: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  title: string;
  children: React.ReactNode;
  cancelButtonTitle?: string;
  continueButtonTitle?: string;
  tooltip?: string;
}

export default function ConfirmDialog(props: IConfirmDialogProps): JSX.Element | null {
  const handleContinue = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    props.continueCallback(event);
  };

  const handleCancel = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();
    props.cancelCallback(event);
  };

  const dialogRoot = document.getElementById('modal-root');

  const { formatMessage: f } = useIntl();

  const handleEscapeKey = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.code === 'Escape' && dialogRoot) {
        props.cancelCallback(event);
      }
    },
    [dialogRoot, props]
  );

  React.useEffect(() => {
    window.addEventListener('keydown', handleEscapeKey);
    return () => {
      window.removeEventListener('keydown', handleEscapeKey);
    };
  }, [handleEscapeKey]);

  const dialogContent = (
    <div
      className="modal modalOpened systemModal modal_onl_graphs"
      id="modalComponent"
      tabIndex={-1}
      role="dialog"
      data-keyboard="false"
      data-backdrop="static"
      aria-labelledby="systemModalLabel"
    >
      <div className="modal-dialog" role="document" style={{ width: '28vw' }}>
        <div className="modal-content">
          <div className="modal-header pl-6">
            <h4>{props.title}</h4>
            <i
              className="confirm-dialog-close cmd-icon cmd-icon-remove"
              aria-hidden="true"
              onClick={handleCancel}
              data-cy="icon-confirm-button-cancel"
            />
          </div>

          <form className="form-horizontal">
            <div className="p-6">
              <p title={props.tooltip || ''}>{props.children}</p>
            </div>
            <div className="modal-footer clearfix">
              <a
                onClick={handleCancel}
                data-cy="confirm-button-cancel"
                type="button"
                href="#"
                id="button-cancel"
                className="e-button"
              >
                {props.cancelButtonTitle
                  ? props.cancelButtonTitle
                  : f({ id: 'confirm.dialog.answerNo' })}
              </a>
              <a
                onClick={handleContinue}
                data-cy="confirm-button-continue"
                type="button"
                href="#"
                id="button-continue"
                className="e-button e-button--gray"
              >
                {props.continueButtonTitle
                  ? props.continueButtonTitle
                  : f({ id: 'confirm.dialog.answerYes' })}
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  if (dialogRoot) {
    return ReactDOM.createPortal(dialogContent, dialogRoot);
  }

  console.error('Dialog root element is not defined.');
  return null;
}
