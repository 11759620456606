import { atom, selector, selectorFamily } from 'recoil';
import { BluecoinData, BluecoinsData, IFiltersBluecoin } from './interfaces';
import Config from '../../../../config';
import { bounds, isClusterEnabled, zoom } from '../OnlineMapState';
import { MIN_POINTS_FOR_DISABLED_CLUSTER } from '../GoogleMapLayer/constants';
import setIsBluecoinsMapOpenToSocketServerEffect from '../../../store/recoil/effects/setIsBluecoinsMapOpenToSocketServerEffect';
import { SuperclusterBluecoinWorker } from '../CreateWorkers';

export const bluecoins = atom<BluecoinsData>({
  key: 'bluecoins',
  default: {},
});

export const selectedBluecoins = atom<number[]>({
  key: 'selectedBluecoins',
  default: [],
});

export const isBluecoinPanelOpen = selector<boolean>({
  key: 'isBluecoinPanelOpen',
  get: ({ get }) => {
    return get(selectedBluecoins).length > 0;
  },
});

export const bluecoinsArray = selector<BluecoinData[]>({
  key: 'bluecoinsArray',
  get: ({ get }) => {
    const bluecoinsData = get(bluecoins);

    const data = Object.keys(bluecoinsData).map((bluecoinId: string) => {
      return bluecoinsData[Number(bluecoinId)];
    });
    return data;
  },
});

export const bluecoinById = selectorFamily<BluecoinData | undefined, number>({
  key: 'bluecoinById',
  get:
    (bluecoinId: number) =>
    ({ get }) => {
      const bluecoinsData = get(bluecoins);
      return bluecoinsData[bluecoinId];
    },
});

export const filteredBluecoinString = atom<string>({
  key: 'filteredBluecoinString',
  default: '',
});

export const filteredBluecoins = atom<BluecoinData[]>({
  key: 'filteredBluecoins',
  default: [],
});

export const activeFilterBluecoins = atom<IFiltersBluecoin | null>({
  key: 'activeFilterBluecoins',
  default: null,
});

export const isBluecoinDataLoading = atom<boolean>({
  key: 'isBluecoinsLoaded',
  default: false,
});

export const isBluecoinsOpen = atom<boolean>({
  key: 'isBluecoinsOpen',
  default: false,
  effects: [setIsBluecoinsMapOpenToSocketServerEffect()],
});

export const bluecoinId = atom<number | null>({
  key: 'bluecoinId',
  default: null,
});

export const bluecoinsData = selector<BluecoinData[]>({
  key: 'bluecoinsData',
  get: ({ get }) => {
    let bluecoinsDataWorker = get(bluecoinsArray);
    const zoomData = get(zoom);
    const boundsData = get(bounds);
    const isClusterEnabledValue = get<boolean>(isClusterEnabled);
    const filteredWaypointsStringWorker = get(filteredBluecoinString);
    const filteredWaypointsWorker = get(filteredBluecoins);
    if (filteredWaypointsStringWorker.length > 0) {
      bluecoinsDataWorker = filteredWaypointsWorker;
    }

    SuperclusterBluecoinWorker.postMessage({
      bluecoinsData: bluecoinsDataWorker,
      bounds: boundsData,
      zoom: zoomData,
      minPoints: isClusterEnabledValue
        ? Config.onlineMap.superCluster.minPoints
        : MIN_POINTS_FOR_DISABLED_CLUSTER,
    });
    return bluecoinsDataWorker;
  },
});

export const isMinimalizedBle = atom<boolean>({
  key: 'isMinimalizedBle',
  default: false,
});
