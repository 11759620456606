import * as React from 'react';
import { Layout } from 'react-grid-layout';
import ApiService from '../../Services/ApiService';
import { getDefaultLanguage, getLocaleNumber } from '../../Services/LanguageService';

const RESPONSE_OK = 'OK';

export interface IWidgetApiResponse {
  id: number;
  category: string;
  rowSize: number;
  columnSize: number;
  size: string;
  type: string;
  icon: string;
  gridName: string;
  hasTitle: boolean;
  hasIcon: boolean;
  hasUnitType: boolean;
  colorizable: boolean;
  clickable: boolean;
}

export interface IWidget {
  id: number | string;
  category: string;
  rowSize: number;
  columnSize: number;
  size: string;
  type: string;
  icon: string;
  gridName: string;
  hasTitle: boolean;
  hasIcon: boolean;
  hasUnitType: boolean;
  colorizable: boolean;
  clickable: boolean;
}

export interface IWidgetCategory {
  item: string;
  value: string;
}

export interface IWidgetType {
  item: string;
  value: string;
}

export const getWidgetById = (widgets: IWidget[], id: number): IWidget | undefined => {
  return widgets.find((widget: IWidget) => widget.id === id);
};

export const getWidgetByGridName = (widgets: IWidget[], name: string): IWidget | undefined => {
  return widgets.find((widget: IWidget) => widget.gridName === name);
};

export const getWidgets = async (): Promise<IWidgetApiResponse[] | null> => {
  const response = await ApiService.get('/v1/online-map/widgets');
  if (response && response.data && response.status === 200) {
    return response.data;
  }
  return null;
};

export const getWidgetCategoryNameById = (categories: IWidgetCategory[], id: string): string => {
  const category = categories.find(
    (widgetCategory: IWidgetCategory) => widgetCategory.value === id
  );
  return category ? category.item : '';
};

export const getWidgetTypeNameById = (types: IWidgetType[], id: string): string => {
  const type = types.find((widgetType: IWidgetType) => widgetType.value === id);
  return type ? type.item : '';
};

export const getWidgetsByLayout = (widgets: IWidget[], layout: Layout[]): IWidget[] => {
  const widgetItems: IWidget[] = [];
  layout.map((item: Layout) => {
    // const [id, stringId] = item.i.split('-');
    // const widgetData = WidgetService.getWidgetByGridName(widgets, stringId);
    const widgetData = getWidgetByGridName(widgets, item.i);
    if (widgetData) {
      // const newWidgetData = {
      //   ...widgetData,
      //   id: item.i,
      // };
      // console.log('New widget: ', newWidgetData);
      // widgetItems.push(newWidgetData);
      widgetItems.push(widgetData);
    }
  });
  return widgetItems;
};

export const getValue = (
  value: number | null | undefined,
  hasUnitType?: boolean,
  valueType?: string
): JSX.Element => {
  const defaultLanguage = getDefaultLanguage();
  if (value === null || value === undefined) {
    return <strong>-</strong>;
  }

  return (
    <>
      <strong>{value.toLocaleString(getLocaleNumber(defaultLanguage))}</strong>
      {hasUnitType && valueType && <small>{valueType}</small>}
    </>
  );
};

export const getTooltip = (
  tooltip: string,
  mockupModeTooltip: string,
  isInGrid?: boolean,
  isMockupMode?: boolean
): string => {
  let text = tooltip;
  if (!isInGrid && isMockupMode) {
    text = mockupModeTooltip;
  }
  return text;
};

export const isDisabled = (widgetData: number | string | null | undefined): string => {
  return widgetData === null || widgetData === undefined ? 'disabled' : '';
};
