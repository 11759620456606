/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { IVehicleOnlinePanels } from '../OnlinePanelState';
import { ICustomWidgetProps } from './Widget';
import * as OnlinePanelState from '../OnlinePanelState';
import { IAnnouncement } from '../../RidePlayer/interfaces';

export default function BluecoinWidget(props: ICustomWidgetProps): JSX.Element {
  const { formatMessage: f } = useIntl();
  const [widgetData, setWidgetData] = React.useState<IAnnouncement[]>([
    {
      type: 'bluecoin',
      duration: 233,
      id: 44555647,
      title: 'Bluecoin 1',
      activatedAt: '2021-05-05T09:00:00.000Z',
    },
    {
      type: 'bluecoin',
      duration: 233,
      id: 565676575,
      title: 'Bluecoin 2',
      activatedAt: '2021-05-05T09:00:00.000Z',
    },
    {
      type: 'bluecoin',
      duration: 233,
      id: 34543553,
      title: 'Bluecoin 3',
      activatedAt: '2021-05-05T09:00:00.000Z',
    },
    {
      type: 'bluecoin',
      duration: 233,
      id: 546557675667,
      title: 'Bluecoin 4',
      activatedAt: '2021-05-05T09:00:00.000Z',
    },
    {
      type: 'bluecoin',
      duration: 233,
      id: 234244325,
      title: 'Bluecoin 5',
      activatedAt: '2021-05-05T09:00:00.000Z',
    },
    {
      type: 'bluecoin',
      duration: 233,
      id: 2423435645,
      title: 'Bluecoin 6',
      activatedAt: '2021-05-05T09:00:00.000Z',
    },
    {
      type: 'bluecoin',
      duration: 233,
      id: 53453453453,
      title: 'Bluecoin 7',
      activatedAt: '2021-05-05T09:00:00.000Z',
    },
    {
      type: 'bluecoin',
      duration: 233,
      id: 76867867,
      title: 'Bluecoin 8',
      activatedAt: '2021-05-05T09:00:00.000Z',
    },
    {
      type: 'bluecoin',
      duration: 233,
      id: 45345434,
      title: 'Bluecoin 9',
      activatedAt: '2021-05-05T09:00:00.000Z',
    },
    {
      type: 'bluecoin',
      duration: 233,
      id: 78677867986,
      title: 'Bluecoin 10',
      activatedAt: '2021-05-05T09:00:00.000Z',
    },
    {
      type: 'bluecoin',
      duration: 233,
      id: 424234,
      title: 'Bluecoin 11',
      activatedAt: '2021-05-05T09:00:00.000Z',
    },
    {
      type: 'bluecoin',
      duration: 233,
      id: 564564564,
      title: 'Bluecoin 12',
      activatedAt: '2021-05-05T09:00:00.000Z',
    },
    {
      type: 'bluecoin',
      duration: 233,
      id: 565464,
      title: 'Bluecoin 13',
      activatedAt: '2021-05-05T09:00:00.000Z',
    },
    {
      type: 'bluecoin',
      duration: 233,
      id: 324234,
      title: 'Bluecoin 14',
      activatedAt: '2021-05-05T09:00:00.000Z',
    },
    {
      type: 'bluecoin',
      duration: 233,
      id: 7567567,
      title: 'Bluecoin 15',
      activatedAt: '2021-05-05T09:00:00.000Z',
    },
    {
      type: 'bluecoin',
      duration: 233,
      id: 43434,
      title: 'Bluecoin 16',
      activatedAt: '2021-05-05T09:00:00.000Z',
    },
    {
      type: 'bluecoin',
      duration: 233,
      id: 34324234,
      title: 'Bluecoin 17',
      activatedAt: '2021-05-05T09:00:00.000Z',
    },
    {
      type: 'bluecoin',
      duration: 233,
      id: 4234234,
      title: 'Bluecoin 18',
      activatedAt: '2021-05-05T09:00:00.000Z',
    },
    {
      type: 'bluecoin',
      duration: 233,
      id: 34324234,
      title: 'Bluecoin 19',
      activatedAt: '2021-05-05T09:00:00.000Z',
    },
    {
      type: 'bluecoin',
      duration: 233,
      id: 4353453453,
      title: 'Bluecoin 20',
      activatedAt: '2021-05-05T09:00:00.000Z',
    },
  ]);
  const vehicleId = useRecoilValue<number | false>(OnlinePanelState.isOnlinePanelOpen);
  const onlinePanelData = useRecoilValue<IVehicleOnlinePanels>(OnlinePanelState.onlinePanel);

  React.useMemo(() => {
    if (!props.isMockupMode) {
      const onlinePanel = onlinePanelData[Number(vehicleId)];

      if (onlinePanel && typeof vehicleId === 'number') {
        const filteredBluecoins = onlinePanelData[vehicleId].announcements.filter(
          (item) => item.type === 'bluecoin'
        );
        setWidgetData(filteredBluecoins);
      } else {
        setWidgetData([]);
      }
    }
  }, [onlinePanelData, props.isMockupMode, vehicleId]);

  const onDragStart = props.isInGrid ? undefined : props.onDragCallback;
  const unselectable = props.isInGrid ? undefined : 'on';

  const getClassNames = (formProps) => {
    let classNames = `b-widget b-widget--${formProps.data.rowSize}x${formProps.data.columnSize}`;

    if (formProps.data.hasIcon && formProps.data.columnSize > 1 && formProps.data.columnSize < 4) {
      classNames += ' b-widget--alignLeft b-widget--carInfo';
    }

    if (formProps.data.rowSize === 2) {
      classNames += ' b-widget--2rows';
    }

    classNames += ` widget-grid-name-${formProps.data.gridName}`;

    if (formProps.data.rowSize === 3 && formProps.data.columnSize === 4) {
      classNames += ' b-widget--alignLeft';
    }

    if (!formProps.isInGrid) {
      classNames += ' droppable-element';
    }

    return classNames;
  };

  const handleWidgetValue = (): string => {
    if (widgetData.length > 1) {
      return `${widgetData.length}  ${f({ id: 'enum.periodLocks.definitionEnabled' })}`;
    }
    if (widgetData.length === 1) {
      return f({ id: 'bluecoin.detail.state.active' });
    }
    return f({ id: 'bluecoin.detail.state.inactive' });
  };

  const handleTooltip = (): string => {
    if (!props.isInGrid && props.isMockupMode) {
      return f({ id: 'onlineTemplates.subPage.section.widgets.tooltip' });
    }
    if (widgetData.length > 0) {
      return widgetData.map((bluecoin: IAnnouncement) => bluecoin.title).join(', ');
    }
    return f({ id: 'bluecoin.detail.state.inactive' });
  };

  return (
    <div
      data-cy="bluecoin-widget"
      className={getClassNames(props)}
      draggable={!props.isInGrid}
      onDragStart={onDragStart}
      unselectable={unselectable}
      title={handleTooltip()}
      style={{ position: 'relative' }}
    >
      {props.isInGrid && (
        <div>
          <a
            data-cy="bluecoin-widget-in-grid"
            style={{ position: 'absolute', top: '1px', right: '1px' }}
            href="#"
            title={f({ id: 'widgets.removeFromGrid.tooltip' })}
            className="cmd-icon cmd-icon-remove-sm close"
            data-grid-name={props.data.gridName}
            onClick={props.onRemoveCallback}
          />
        </div>
      )}
      {props.data.columnSize === 1 && props.data.rowSize === 1 && (
        <>
          <i
            data-cy="bluecoin-widget-icon-size-1"
            className="e-widget-icon cmd-icon cmd-icon-bluecoin"
            style={widgetData.length > 0 ? { color: '#7B235F' } : { color: '#C8C8C8' }}
          />
          <div
            className="e-widget-value"
            style={
              widgetData.length > 0
                ? {
                    color: '#7B235F',
                    fontSize: '12px',
                    display: 'inline-block',
                  }
                : { color: '#C8C8C8', fontSize: '12px' }
            }
          >
            <strong>{handleWidgetValue()}</strong>
          </div>
        </>
      )}
      {props.data.columnSize === 3 && props.data.rowSize === 1 && (
        <>
          <i
            data-cy="alarm-widget-value-size-3-row-1"
            className={`e-widget-icon e-widget-icon--md cmd-icon cmd-icon-bluecoin ${
              !props.isMockupMode ? 'active' : ''
            }`}
            style={widgetData.length > 0 ? { color: '#7B235F' } : { color: '#C8C8C8' }}
          />
          {widgetData.length > 0 && (
            <>
              {widgetData.length <= 3 &&
                widgetData.map((bluecoin: IAnnouncement) => (
                  // <li key={bluecoin} title={bluecoin}>
                  <span style={{ marginLeft: '8px', marginTop: '4px' }} key={bluecoin.id}>
                    {bluecoin.title}
                  </span>
                  // </li>
                ))}
              {widgetData.length > 3 &&
                widgetData.map(
                  (bluecoin: IAnnouncement, index: number) =>
                    index < 2 && (
                      // <li key={bluecoin} title={bluecoin}>
                      <span style={{ marginLeft: '8px', marginTop: '4px' }} key={bluecoin.id}>
                        {bluecoin.title}
                      </span>
                      // </li>
                    )
                )}
              {widgetData.length > 3 && (
                <span style={{ marginLeft: '8px', marginTop: '4px' }}>
                  +{widgetData.length - 2}
                </span>
              )}
            </>
          )}
          {widgetData.length === 0 && (
            <div
              className="e-widget-value"
              style={
                widgetData.length > 0
                  ? { fontSize: '13px' }
                  : { color: '#C8C8C8', fontSize: '13px' }
              }
            >
              <strong>{f({ id: 'enum.periodLocks.definitionDisabled' })}</strong>
            </div>
          )}
        </>
      )}
      {props.data.columnSize === 4 && props.data.rowSize === 3 && (
        <>
          {widgetData.length > 0 && (
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
              {widgetData.length <= 7 &&
                widgetData.map((bluecoin: IAnnouncement) => (
                  <li style={{ paddingTop: '5px' }} key={bluecoin.id} title={bluecoin.title}>
                    <i
                      data-cy="bluecoin-widget-value-size-4-row-3"
                      className={`e-widget-icon cmd-icon cmd-icon-bluecoin ${
                        !props.isMockupMode ? 'active' : ''
                      }`}
                      style={{ color: '#7B235F', fontSize: '16px', paddingRight: '5px' }}
                    />
                    <span style={{ marginLeft: '8px', marginTop: '4px' }}>{bluecoin.title}</span>
                  </li>
                ))}
              {widgetData.length > 7 &&
                widgetData.map(
                  (bluecoin: IAnnouncement, index: number) =>
                    index < 7 && (
                      <li
                        style={{ paddingTop: '5px', marginTop: '4px' }}
                        key={bluecoin.id}
                        title={bluecoin.title}
                      >
                        <i
                          data-cy="bluecoin-widget-value-size-4-row-3"
                          className={`e-widget-icon cmd-icon cmd-icon-bluecoin ${
                            !props.isMockupMode ? 'active' : ''
                          }`}
                          style={{ color: '#7B235F', fontSize: '16px', paddingRight: '5px' }}
                        />
                        <span style={{ marginLeft: '8px' }}>{bluecoin.title}</span>
                      </li>
                    )
                )}
              {widgetData.length > 7 && (
                <li style={{ paddingTop: '5px', marginTop: '4px' }}>
                  <span style={{ marginLeft: '26px', marginTop: '4px' }}>
                    +{widgetData.length - 7}
                  </span>
                </li>
              )}
            </ul>
          )}
          {widgetData.length === 0 && <>{f({ id: 'bluecoin.widget.noData' })}</>}
        </>
      )}
      {props.data.columnSize === 11 && props.data.rowSize === 3 && (
        <>
          {widgetData.length > 0 && (
            <div className="row">
              {widgetData.length <= 7 &&
                widgetData.map((bluecoin: IAnnouncement) => (
                  <div
                    className="col-12 col-md-6 col-lg-4 my-2"
                    key={bluecoin.id}
                    title={bluecoin.title}
                  >
                    <i
                      data-cy="bluecoin-widget-value-size-4-row-3"
                      className={`e-widget-icon cmd-icon cmd-icon-bluecoin ${
                        !props.isMockupMode ? 'active' : ''
                      }`}
                      style={{ color: '#7B235F', fontSize: '16px', paddingRight: '5px' }}
                    />
                    <span style={{ marginLeft: '8px', marginTop: '4px' }}>{bluecoin.title}</span>
                  </div>
                ))}
              {widgetData.length > 7 &&
                widgetData.map(
                  (bluecoin: IAnnouncement, index: number) =>
                    index < 17 && (
                      <div
                        className="col-12 col-md-6 col-lg-4 my-2"
                        key={bluecoin.id}
                        title={bluecoin.title}
                      >
                        <i
                          data-cy="bluecoin-widget-value-size-4-row-3"
                          className={`e-widget-icon cmd-icon cmd-icon-bluecoin ${
                            !props.isMockupMode ? 'active' : ''
                          }`}
                          style={{ color: '#7B235F', fontSize: '16px', paddingRight: '5px' }}
                        />
                        <span style={{ marginLeft: '8px' }}>{bluecoin.title}</span>
                      </div>
                    )
                )}
              {widgetData.length > 17 && (
                <div>
                  <span style={{ marginLeft: '43px', marginTop: '4px' }}>
                    +{widgetData.length - 17}
                  </span>
                </div>
              )}
            </div>
          )}
          {widgetData.length === 0 && <>{f({ id: 'bluecoin.widget.noData' })}</>}
        </>
      )}
    </div>
  );
}
